import axios from 'axios';

export default {
  namespaced: true,
  state: {
    investigation: {
      id: 0,
      leasing_request: 0,
      request_date: null,
      visit_date: null,
      branch: null,
      branch_checkbox: null,
      company_name: '',
      company_name_checkbox: null,
      trade_name: '',
      trade_name_checkbox: null,
      company_legal_structure: '',
      company_legal_structure_checkbox: null,
      amount_of_capital: null,
      last_capital_increase_date: null,
      authorized_capital: null,
      capital_paid: null,
      issued_capital: null,
      business_start_date: null,
      business_start_date_checkbox: null,
      number_of_branches: null,
      number_of_branches_checkbox: null,
      sector: null,
      sector_checkbox: null,
      industry: null,
      industry_checkbox: null,
      sub_industry: null,
      sub_industry_checkbox: null,
      commercial_register_number: null,
      commercial_register_number_checkbox: null,
      real_activity: '',
      real_activity_checkbox: null,
      last_renewal_date: null,
      last_renewal_date_checkbox: null,
      license_renewal_date: null,
      license_renewal_date_checkbox: null,
      license_number: '',
      license_number_checkbox: null,
      other_activities: '',
      other_activities_checkbox: null,
      governorate: null,
      governorate_checkbox: null,
      directorate: null,
      directorate_checkbox: null,
      district: null,
      district_checkbox: null,
      street: '',
      street_checkbox: null,
      neighbor: '',
      neighbor_checkbox: null,
      headquarter_ownership: null,
      headquarter_ownership_checkbox: null,
      telephone: '',
      telephone_checkbox: null,
      mobile_number: '',
      mobile_number_checkbox: null,
      email_address: '',
      email_address_checkbox: null,
      responsible_rm: '',
      responsible_rm_checkbox: null,
      contact_person: '',
      contact_person_checkbox: null,
      contact_person_email_address: '',
      contact_person_email_address_checkbox: null,
      contact_person_number: '',
      contact_person_number_checkbox: null,
      responsible_for_management: '',
      responsible_for_management_checkbox: null,
      manager_relation: null,
      manager_relation_checkbox: null,
      manager_mobile_number: '',
      manager_mobile_number_checkbox: null,
      summary: '',
      summary_checkbox: null,

      owners_years_of_experience: null,
      owners_years_of_experience_comment: '',
      current_employee_number: null,
      current_employee_number_comment: '',
      permanent_employee_number: null,
      permanent_employee_number_comment: '',
      seasonal_employee_number: null,
      seasonal_employee_number_comment: '',
      ideal_employee_number: null,
      ideal_employee_number_comment: '',
      staff_trained: '',
      staff_trained_comment: '',
      technical_management_expertise: '',
      technical_management_expertise_comment: '',
      company_structure: '',
      company_structure_comment: '',
      future_plans: '',
      future_plans_comment: '',
      key_person: '',
      key_person_comment: '',
      equipment_count: null,
      equipment_count_comment: '',
      equipment_production_year: null,
      equipment_production_year_comment: '',
      equipment_needs_maintenance: '',
      equipment_needs_maintenance_comment: '',
      equipment_maintained_before: '',
      equipment_maintained_before_comment: '',
      enough_equipment: '',
      enough_equipment_comment: '',
      equipment_used_more_than_normal: '',
      equipment_used_more_than_normal_comment: '',
      equipment_current_production_size: null,
      equipment_current_production_size_comment: '',
      similar_equipment_production_size: null,
      similar_equipment_production_size_comment: '',
      project_location_close_to_city: '',
      project_location_close_to_city_comment: '',
      project_location_accessible: '',
      project_location_accessible_comment: '',
      project_location_visible: '',
      project_location_visible_comment: '',
      similar_projects_in_location: '',
      similar_projects_in_location_comment: '',
      active_market_for_project_activity: '',
      active_market_for_project_activity_comment: '',
      occupational_safety_problems: '',
      occupational_safety_problems_comment: '',
      headquarters_size_appropriate: '',
      headquarters_size_appropriate_comment: '',
      branches_exist: '',
      branches_exist_comment: '',
      branch_count: null,
      branch_count_comment: '',
      headquarter_ownership_comment: '',
      headquarters_maintained: '',
      headquarters_maintained_comment: '',
      enough_raw_materials: '',
      enough_raw_materials_comment: '',
      work_progress: '',
      work_progress_comment: '',
      goods_finish_and_size_appropriate: '',
      goods_finish_and_size_appropriate_comment: '',
      product_condition: '',
      product_condition_comment: '',
      quality_control_review: '',
      quality_control_review_comment: '',
      management_employees_summary: '',
      insurance_status: '',
      tax_status: '',
      tax_insurance_summary: '',
      cbe_code: '',
      cbe_industry_code: '',
      total_authorized_facilities: null,
      total_used_facilities: null,
      number_of_banks: null,
      date_of_acp: null,
      cbe_position_summary: '',
      commercial_register: '',
      cbe: '',
      mortgages_summary: '',
      ecr_number: '',
      description_of_assets_registered: '',
      amount_of_assets_registered: '',
      date_of_registration: '',
      guarantor_guarantee_summary: '',
      iscore_information_summary: '',
      iscore_summary: null,
      protesto_comment: '',
      bankruptcy_comment: '',
      protesto_bankruptcy_summary: '',
      request_status: '',
      created_by: null,
      last_modified_by: null,
      last_modified_date: null,
      company_ownership_structure: [],
      main_clients_information: [],
      main_suppliers_information: [],
      cbe_position: [],
      bank_information: [],
      iscore_information: [],
      guarantor: [],
      guarantee: [],
      name: '',
      iscore_rating: 0,
      situation_of_negative_list: '',
    },
    summarizedInvestigation: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
  },
  mutations: {
    updateInvestigation(state, newInvestigation) {
      state.investigation = newInvestigation;
    },
    resetInvestigation(state) {
      state.investigation = {
        id: 0,
        leasing_request: 0,
        request_date: null,
        visit_date: null,
        branch: null,
        branch_checkbox: null,
        company_name: '',
        company_name_checkbox: null,
        trade_name: '',
        trade_name_checkbox: null,
        company_legal_structure: '',
        company_legal_structure_checkbox: null,
        amount_of_capital: null,
        last_capital_increase_date: null,
        authorized_capital: null,
        capital_paid: null,
        issued_capital: null,
        business_start_date: null,
        business_start_date_checkbox: null,
        number_of_branches: null,
        number_of_branches_checkbox: null,
        sector: null,
        sector_checkbox: null,
        industry: null,
        industry_checkbox: null,
        sub_industry: null,
        sub_industry_checkbox: null,
        commercial_register_number: null,
        commercial_register_number_checkbox: null,
        real_activity: '',
        real_activity_checkbox: null,
        last_renewal_date: null,
        last_renewal_date_checkbox: null,
        license_renewal_date: null,
        license_renewal_date_checkbox: null,
        license_number: '',
        license_number_checkbox: null,
        other_activities: '',
        other_activities_checkbox: null,
        governorate: null,
        governorate_checkbox: null,
        directorate: null,
        directorate_checkbox: null,
        district: null,
        district_checkbox: null,
        street: '',
        street_checkbox: null,
        neighbor: '',
        neighbor_checkbox: null,
        headquarter_ownership: null,
        headquarter_ownership_checkbox: null,
        telephone: '',
        telephone_checkbox: null,
        mobile_number: '',
        mobile_number_checkbox: null,
        email_address: '',
        email_address_checkbox: null,
        responsible_rm: '',
        responsible_rm_checkbox: null,
        contact_person: '',
        contact_person_checkbox: null,
        contact_person_email_address: '',
        contact_person_email_address_checkbox: null,
        contact_person_number: '',
        contact_person_number_checkbox: null,
        responsible_for_management: '',
        responsible_for_management_checkbox: null,
        manager_relation: null,
        manager_relation_checkbox: null,
        manager_mobile_number: '',
        manager_mobile_number_checkbox: null,
        summary: '',
        summary_checkbox: null,

        owners_years_of_experience: null,
        owners_years_of_experience_comment: '',
        current_employee_number: null,
        current_employee_number_comment: '',
        permanent_employee_number: null,
        permanent_employee_number_comment: '',
        seasonal_employee_number: null,
        seasonal_employee_number_comment: '',
        ideal_employee_number: null,
        ideal_employee_number_comment: '',
        staff_trained: '',
        staff_trained_comment: '',
        technical_management_expertise: '',
        technical_management_expertise_comment: '',
        company_structure: '',
        company_structure_comment: '',
        future_plans: '',
        future_plans_comment: '',
        key_person: '',
        key_person_comment: '',
        equipment_count: null,
        equipment_count_comment: '',
        equipment_production_year: null,
        equipment_production_year_comment: '',
        equipment_needs_maintenance: '',
        equipment_needs_maintenance_comment: '',
        equipment_maintained_before: '',
        equipment_maintained_before_comment: '',
        enough_equipment: '',
        enough_equipment_comment: '',
        equipment_used_more_than_normal: '',
        equipment_used_more_than_normal_comment: '',
        equipment_current_production_size: null,
        equipment_current_production_size_comment: '',
        similar_equipment_production_size: null,
        similar_equipment_production_size_comment: '',
        project_location_close_to_city: '',
        project_location_close_to_city_comment: '',
        project_location_accessible: '',
        project_location_accessible_comment: '',
        project_location_visible: '',
        project_location_visible_comment: '',
        similar_projects_in_location: '',
        similar_projects_in_location_comment: '',
        active_market_for_project_activity: '',
        active_market_for_project_activity_comment: '',
        occupational_safety_problems: '',
        occupational_safety_problems_comment: '',
        headquarters_size_appropriate: '',
        headquarters_size_appropriate_comment: '',
        branches_exist: '',
        branches_exist_comment: '',
        branch_count: null,
        branch_count_comment: '',
        headquarter_ownership_comment: '',
        headquarters_maintained: '',
        headquarters_maintained_comment: '',
        enough_raw_materials: '',
        enough_raw_materials_comment: '',
        work_progress: '',
        work_progress_comment: '',
        goods_finish_and_size_appropriate: '',
        goods_finish_and_size_appropriate_comment: '',
        product_condition: '',
        product_condition_comment: '',
        quality_control_review: '',
        quality_control_review_comment: '',
        management_employees_summary: '',
        insurance_status: '',
        tax_status: '',
        tax_insurance_summary: '',
        cbe_code: '',
        cbe_industry_code: '',
        total_authorized_facilities: null,
        total_used_facilities: null,
        number_of_banks: null,
        date_of_acp: null,
        cbe_position_summary: '',
        commercial_register: '',
        cbe: '',
        mortgages_summary: '',
        ecr_number: '',
        description_of_assets_registered: '',
        amount_of_assets_registered: '',
        date_of_registration: '',
        guarantor_guarantee_summary: '',
        iscore_information_summary: '',
        iscore_summary: null,
        protesto_comment: '',
        bankruptcy_comment: '',
        protesto_bankruptcy_summary: '',
        request_status: '',
        created_by: null,
        last_modified_by: null,
        last_modified_date: null,
        company_ownership_structure: [],
        main_clients_information: [],
        main_suppliers_information: [],
        cbe_position: [],
        bank_information: [],
        iscore_information: [],
        guarantor: [],
        guarantee: [],
        name: '',
        iscore_rating: 0,
        situation_of_negative_list: '',
      };
    },
    updateSummarizedInvestigation(state, newInvestigation) {
      state.summarizedInvestigation = newInvestigation;
    },
  },
  actions: {
    saveInvestigation(context, investigationInfo) {
      const investigationId = Number(investigationInfo.id);
      console.log('investigation id', investigationId);
      console.log('store here', investigationId === '0');
      const reqMethod = investigationId > 0 ? 'put' : 'post';
      const reqUrl = investigationId > 0
        ? `/api/leasing/investigations/${investigationId}/`
        : '/api/leasing/investigations/';
      return new Promise((resolve, reject) => {
        axios({
          url: reqUrl,
          method: reqMethod,
          data: investigationInfo,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getInvestigation(context, investigationId) {
      console.log('this investigation id', investigationId);
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/leasing/investigations/${investigationId}/`,
          method: 'get',
        }).then((response) => {
          if (investigationId > 0) {
            context.commit('updateInvestigation', response.data);
          } else {
            context.commit('resetInvestigation');
          }
          resolve(response.data);
        }).catch((error) => {
          context.commit('resetInvestigation');
          reject(error.response.data);
        });
      });
    },
    getSummarizedInvestigation(context, lazyParams) {
      console.log('page number:', lazyParams);
      console.log('Here is the investigation:');
      console.log('filter:', lazyParams.filters);
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/leasing/investigations/',
          method: 'get',
          params: {
            page: lazyParams.page + 1,
            filters: lazyParams.filters,
          },
        }).then((response) => {
          context.commit('updateSummarizedInvestigation', response.data);
          resolve(response);
        }).catch((error) => {
          context.commit('updateSummarizedInvestigation', []);
          reject(error.response.data);
        });
      });
    },
  },
  getters: {
    getInvestigation(state) {
      return state.investigation;
    },
    getSummarizedInvestigation(state) {
      return state.summarizedInvestigation;
    },
  },
};
