import { createRouter, createWebHistory } from 'vue-router';
import AppLayout from '@/layout/AppLayout.vue';
import store from '@/store';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: AppLayout,
      children: [
        {
          path: '/',
          name: 'dashboard',
          component: () => import('@/views/Dashboard.vue'),
        },
      ],
    },
    {
      path: '/micro',
      name: 'dashboard',
      component: () => import('@/views/Dashboard2.vue'),
    },
    {
      path: '/leasing',
      component: () => import('@/views/Dashboard2.vue'),
    },
    {
      path: '/scoring',
      name: 'Scoring',
      component: () => import('@/views/OrrScore.vue'),
    },
    {
      path: '/module',
      name: 'ModuleSelector',
      component: () => import('@/views/ModuleSelector.vue'),
    },
    {
      path: '/auth/login',
      name: 'login',
      component: () => import('@/views/pages/auth/Login.vue'),
    },
    {
      path: '/auth/forget-password',
      name: 'forgetpassword',
      component: () => import('@/views/pages/auth/ForgetPassword.vue'),
    },
    {
      path: '/auth/password/reset/confirm/:uid/:token/',
      name: 'resetpassword',
      component: () => import('@/views/pages/auth/ResetPassword.vue'),
    },
    {
      path: '/auth/change-password',
      name: 'changepassword',
      component: () => import('@/views/pages/auth/ChangePassword.vue'),
    },
    {
      path: '/customer/:id',
      name: 'customerdetails',
      component: () => import('@/views/pages/customers/CustomerDetails.vue'),
    },
    {
      path: '/customers/add-customer',
      name: 'addcustomer',
      component: () => import('@/views/pages/customers/CustomerForm.vue'),
      props: { customerId: 0, editable: true },
    },
    {
      path: '/customers/customer-list',
      name: 'customerlist',
      component: () => import('@/views/pages/customers/CustomersList.vue'),
    },
    {
      path: '/investigations-list',
      name: 'InvestigationsList',
      component: () => import('@/views/pages/investigations/InvestigationsList.vue'),
    },
    {
      path: '/layout-template',
      name: 'LayoutTemplate',
      component: () => import('@/views/pages/customers/LayoutTemplate.vue'),
      props: { customerId: 0, editable: true },
    },
    {
      path: '/investigation/add-investigation',
      name: 'InvestigationForm',
      component: () => import('@/views/pages/investigations/InvestigationForm.vue'),
      props: { investigationId: 0, editable: true },
    },
    {
      path: '/investigation/:customerId/:requestId/:investigationId',
      name: 'Investigationdetails',
      component: () => import('@/views/pages/investigations/InvestigationDetails.vue'),
    },
    {
      path: '/customer/:id',
      name: 'CustomerDetails',
      component: () => import('@/views/pages/customers/CustomerDetails.vue'),
    },
    {
      path: '/request/:customerId/:requestId',
      name: 'RequestDetails',
      component: () => import('@/views/pages/requests/RequestDetails.vue'),
    },
    {
      path: '/request/request-list',
      name: 'RequestList',
      component: () => import('@/views/pages/requests/RequestList.vue'),
    },
    {
      path: '/timeline',
      name: 'Timeline',
      component: () => import('@/views/pages/timeline/timeline.vue'),
    },
    {
      path: '/requestform-duplicate',
      name: 'Duplicate',
      component: () => import('@/views/pages/customers/requestform-duplicate.vue'),
    },
    {
      path: '/guarantee/:investigationId/',
      name: 'Guarantee',
      component: () => import('@/views/pages/guarantee/GuaranteeForm.vue'),
    },
    {
      path: '/facilities-details/:investigationId/',
      name: 'FacilitiesDetails',
      component: () => import('@/views/pages/Facilities-details/FacilitiesDetails.vue'),
    },
    {
      path: '/required-documents/:requestId',
      name: 'RequiredDocuments',
      component: () => import('@/views/pages/documents/required-documents.vue'),

    },
    {
      path: '/liab-equity',
      name: 'LiabEquity',
      component: () => import('@/views/pages/credit/LiabEquity.vue'),
    },
    {
      path: '/income-statement',
      name: 'IncomeStatement',
      component: () => import('@/views/pages/credit/IncomeStatement.vue'),
    },
    {
      path: '/assets',
      name: 'Assets',
      component: () => import('@/views/pages/credit/Assets.vue'),
    },
    {
      path: '/assets',
      name: 'Assets',
      component: () => import('@/views/pages/credit/Assets.vue'),
    },
    {
      path: '/credit-details/:investigationId',
      name: 'CreditDetails',
      component: () => import('@/views/pages/credit/CreditDetails.vue'),
    },
    {
      path: '/credit-memo-details/:investigationId/:urlMethod',
      name: 'CreditMemoDetails',
      component: () => import('@/views/pages/credit/CreditMemoDetails.vue'),
    },
    {
      path: '/credit-risk-memo-details/:investigationId/:urlMethod',
      name: 'CreditRiskMemoDetails',
      component: () => import('@/views/pages/credit/CreditRiskMemoDetails.vue'),
    },
    {
      path: '/request-monitor',
      name: 'RequestMonitor',
      component: () => import('@/views/pages/timeline/RequestMonitor.vue'),
    },
    {
      path: '/request-history/:requestId',
      name: 'RequestHistory',
      component: () => import('@/views/pages/timeline/RequestHistory.vue'),
    },
    {
      path: '/orr-details/:investigationId',
      name: 'OrrDetails',
      component: () => import('@/views/pages/credit/OrrDetails.vue'),
    },
    {
      path: '/credit-list',
      name: 'CreditList',
      component: () => import('@/views/pages/credit/CreditList.vue'),
    },
    {
      path: '/risk-list',
      name: 'RisktList',
      component: () => import('@/views/pages/risk/RiskList.vue'),
    },
    {
      path: '/projection-details/:investigationId',
      name: 'ProjectionDetails',
      component: () => import('@/views/pages/risk/ProjectionDetails.vue'),
    },
    {
      path: '/repayment',
      name: 'Repayment',
      component: () => import('@/views/pages/risk/projection/Repayment.vue'),
    },
    {
      path: '/fixed-asset-projection',
      name: 'FixedAssetProjection',
      component: () => import('@/views/pages/risk/projection/FixedAssetProjection.vue'),
    },
    {
      path: '/projection-assumptions-table',
      name: 'ProjectionAssumptionTable',
      component: () => import('@/views/pages/risk/projection/ProjectionAssumptionTable.vue'),
    },
    {
      path: '/loanrunofftableex',
      name: 'LoanRunoffTableEx',
      component: () => import('@/views/pages/risk/projection/LoanRunoffTableEx.vue'),
    },
    {
      path: '/committee-list/',
      name: 'CommitteeList',
      component: () => import('@/views/pages/committee/CommitteeList.vue'),
    },
    {
      path: '/committee-form/:investigationId',
      name: 'CommitteeForm',
      component: () => import('@/views/pages/committee/CommitteeForm.vue'),
    },
    {
      path: '/report-center-details/:investigationId',
      name: 'ReportCenterDetails',
      component: () => import('@/views/pages/report-center/ReportCenterDetails.vue'),
    },
    {
      path: '/report-center',
      name: 'ReportCenter',
      component: () => import('@/views/pages/report-center/ReportCenter.vue'),
    },
    {
      path: '/charts',
      name: 'Charts',
      component: () => import('@/views/pages/charts/Charts.vue'),
    },
    {
      path: '/header-card/:investigationId',
      name: 'HeaderCard',
      component: () => import('@/views/pages/header-cards/HeaderCards.vue'),
    },
    {
      path: '/no-url/not-allowed-to-access',
      name: 'Landing',
      component: () => import('@/views/pages/Landing/Landing.vue'),
    },
    {
      path: '/main-report',
      name: 'MainReport',
      component: () => import('@/views/pages/report-center/MainReport.vue'),
    },
    {
      path: '/detailed-report',
      name: 'DetailedReport',
      component: () => import('@/views/pages/report-center/DetailedReport.vue'),
    },
    {
      path: '/industry-concentration-report',
      name: 'IndustryConcentrationReport',
      component: () => import('@/views/pages/report-center/IndustryConcentrationReport.vue'),
    },
    {
      path: '/credit-memo-export/:id',
      name: 'CreditMemoExport',
      component: () => import('@/views/pages/reports-export/CreditMemoExport.vue'),
    },
    {
      path: '/credit-risk-memo-export/:id',
      name: 'CreditRiskMemoExport',
      component: () => import('@/views/pages/reports-export/CreditRiskMemoExport.vue'),
    },

    // Micro Urls
    {
      path: '/micro-income-statement',
      name: 'micro-incomestatement',
      component: () => import('@/views/pages/micro/incomeStatement/IncomeStatement.vue'),
    }, {
      path: '/cashflow-statement',
      name: 'cashflow-incomestatement',
      component: () => import('@/views/pages/micro/cashflow/Cashflow.vue'),
    },
    {
      path: '/micro-customer/:id',
      name: 'micro-customerdetails',
      component: () => import('@/views/pages/micro/customers/CustomerDetails.vue'),
    },
    {
      path: '/customers/micro-add-customer',
      name: 'micro-addcustomer',
      component: () => import('@/views/pages/micro/customers/CustomerForm.vue'),
      props: { customerId: 0, editable: true },
    },
    {
      path: '/customers/micro-customer-list',
      name: 'micro-customerlist',
      component: () => import('@/views/pages/micro/customers/CustomersList.vue'),
    },
    {
      path: '/customers/new-micro-request/',
      name: 'micro-requestform',
      component: () => import('@/views/pages/micro/AddingRequest.vue'),
    },
    {
      path: '/micro-credit-details/',
      name: 'Micro-CreditDetails',
      component: () => import('@/views/pages/micro/credit/CreditDetails.vue'),
    },
    {
      path: '/micro-guarantee/:investigationId/',
      name: 'Micro Guarantee',
      component: () => import('@/views/pages/micro/GuaranteeForm.vue'),
    },
    {
      path: '/micro-facilities-details/:investigationId/',
      name: 'Micro FacilitiesDetails',
      component: () => import('@/views/pages/micro/FacilitiesDetails.vue'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/auth/login', '/auth/forget-password', '/auth/password/reset/confirm/:uid/:token/', '/auth/change-password'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = store.getters['users/isAuthenticated'];

  if (authRequired && !loggedIn) {
    // Redirect to the login page
    next({ path: '/auth/login' });
  } else {
    // Proceed to the route
    next();
  }
});

export default router;
