const customerFormPage = {
  generalInformation: 'معلومات عامة',
  requestDate: 'تاريخ الطلب',
  customerNumber: 'رقم العميل',
  branch: 'الفرع',
  companyName: 'اسم الشركة',
  tradeName: 'الاسم التجاري',
  contactPerson: 'الشخص المسؤول',
  contactPersonNumber: 'رقم الشخص المسؤول',
  contactPersonEmail: 'بريد الشخص المسؤول',
  responsibleRm: 'المدير المسؤول',
  businessStartDate: 'تاريخ بدء العمل',
  numberOfEmployees: 'عدد الموظفين',
  numberOfBranches: 'عدد الفروع',
  sector: 'القطاع',
  industry: 'الصناعة',
  subIndustry: 'الصناعة الفرعية',
  realActivity: 'النشاط الفعلي',
  otherActivities: 'أنشطة أخرى',
  activityLicenseNumber: 'رقم رخصة النشاط',
  lastRenewalDate: 'تاريخ آخر تجديد',
  licenseExpireDate: 'تاريخ انتهاء الرخصة',
  companyLegalStructure: 'الهيكل القانوني للشركة',
  activityStreet: 'شارع النشاط',
  governorate: 'المحافظة',
  directorate: 'المديرية',
  district: 'الحي',
  neighbor: 'الجار',
  activityHousingOwnership: 'ملكية سكن النشاط',
  commercialRegisterNumber: 'رقم السجل التجاري',
  telephone: 'هاتف',
  activityMobileNumber: 'رقم الجوال الخاص بالنشاط',
  activityEmailAddress: 'بريد إلكتروني للنشاط',
  responsibleForManagement: 'المسؤول عن الإدارة',
  managerRelation: 'علاقة المدير',
  managerMobileNumber: 'رقم جوال المدير',
  managerEmail: 'بريد المدير الإلكتروني',
  companyInfoFile: 'ملف معلومات الشركة',
  uploadFiles: 'تحميل الملفات',
  addPartner: 'إضافة شريك',
  saveAsDraft: 'حفظ كمسودة',
  submit: 'إرسال',
  cancel: 'إلغاء',
  confirm: 'تأكيد',
  areYouSureDelete: 'هل أنت متأكد أنك تريد حذف هذا الشريك؟',
  areYouSureSave: 'هل أنت متأكد أنك تريد الحفظ؟',
  areYouSureDeleteFiles: 'هل أنت متأكد أنك تريد حذف هذه الملفات؟',
  showFiles: 'عرض الملفات',
  delete: 'حذف',
  ownerName: 'اسم المالك',
  percentage: 'النسبة المئوية',
  partnerBirthDate: 'تاريخ ميلاد الشريك',
  partnerAge: 'عمر الشريك',
  educationLevel: 'المستوى التعليمي',
  yearsOfExperience: 'سنوات الخبرة',
  summary: 'ملخص',
  generalInfo: 'معلومات عامة',
  activityData: 'بيانات النشاط',
  ownershipInformation: 'معلومات الملكية',
  commercialRegisterFile: 'ملف سجل التجاري',
  companyInformation: 'معلومات الشركة',
  showComercialRegister: 'عرض ملف سجل التجاري',
  idInformation: 'معلومات الهوية',
  clientName: 'اسم العميل',
  customerIdType: 'نوع الهوية',
  customerIdNumber: 'رقم الهوية',
  customerIdFile: 'ملف الهوية',
  showCustomerIdFile: 'عرض ملف الهوية',
  idIssueDate: 'تاريخ صدور الهوية',
  idExpireDate: 'تاريخ انتهاء صلاحية الهوية',
  idIssuePlace: 'مكان صدور الهوية',
  idType: 'نوع الهوية',
  idNumber: 'رقم الهوية',
  releaseDate: 'تاريخ الاصدار',
  birthDate: 'تاريخ الميلاد',
  birthCountray: 'بلد الميلاد',
  gender: 'الجنس',
  nationality: 'الجنسية',
  idFile: 'ملف الهوية',
  maritalStatus: 'الحالة الإجتماعية',
  noOfSons: 'عدد الابناء',
  personalInfo: 'معلومات شخصية',
  street: 'الشارع',
  housingOwnership: 'ملكية السكن',
  mobileNumber: 'رقم الجوال',
  email: 'البريد الالكتروني',
  employerName: 'اسم صاحب العمل',
  employerStatus: 'حالة صاحب العمل',
  jobTitle: 'المسمى الوظيفي',
  lengthOfEmployement: 'مدة العمل',
  monthlyIncome: 'الدخل شهري',
  otherIncomeSources: 'مصادر الدخل الاخرى',
  uploadCustomerHousingOwnership: 'تحميل ملكية سكن العميل',
  showCustomerHousingOwnership: 'عرض ملكية سكن العميل',
  atLeastOnePartner: 'يجب أدخال شريك واحد على الأقل!',
  ownerPercentage: 'النسبة المئوية',
  no: 'لا',
  yes: 'نعم',
  ok: 'حسنا',
  requestedLoanInfo: 'بيانات التمويل المطلوب',
  typeOfFacility: 'نوع التمويل',
  currency: 'العملة',
  amount: 'المبلغ',
  facilityDuration: 'مدة التمويل',
  purposeOfLoan: 'الغرض من التمويل',
  interestRateType: 'نوع الفائدة',
  interestRate: 'الفائدة',
  repaymentFrequencyType: 'نوع الدفع',
  repaymentFrequencyValue: 'قيمة الدفع',
  repaymentFrequencyTotal: 'مجموع الدفع',
  repaymentFrequencyDueDate: 'تاريخ الدفع',
};
const requestFormPage = {
  totalRequests: 'إجمالي الطلبات',
  customerRequestInfo: 'معلومات طلب العميل',
  requestDate: 'تاريخ الطلب',
  serialNumber: 'الرقم التسلسلي',
  customerNumber: 'رقم العميل',
  branch: 'الفرع',
  companyName: 'اسم الشركة',
  tradeName: 'الاسم التجاري',
  typeOfRequest: 'نوع الطلب',
  typeOfFacility: 'نوع المنشأة',
  facilityDuration: 'مدة المنشأة',
  typeOfInstallment: 'نوع القسط',
  assetsInformation: 'معلومات الأصول',
  addAsset: 'إضافة أصل',
  assetToBeFinanced: 'الأصل المراد تمويله',
  assetType: 'نوع الأصل',
  assetDescription: 'وصف الأصل',
  assetCost: 'تكلفة الأصل',
  downPaymentSecurityMargin: 'الدفع المقدم (نسبة الأمان)',
  downPayment: 'الدفع المقدم',
  currency: 'العملة',
  purposeOfFinance: 'غرض التمويل',
  assetCondition: 'حالة الأصل',
  dateOfOrigin: 'تاريخ الأصل',
  organizationCountry: 'بلد المنظمة',
  supplierType: 'نوع المورد',
  depositorName: 'اسم المودع',
  assetManufacture: 'تصنيع الأصل',
  ownershipDocumentationType: 'نوع وثائق الملكية',
  speedConversionToCash: 'سرعة التحويل إلى نقد',
  assetValueChanging: 'تغيير قيمة الأصل',
  evaluationReportDate: 'تاريخ تقرير التقييم',
  evaluationReportRenewalDate: 'تاريخ تجديد تقرير التقييم',
  supplierInformation: 'معلومات المورد',
  supplierName: 'اسم المورد',
  assetDepreciation: 'استهلاك الأصل',
  insuranceDate: 'تاريخ التأمين',
  insuranceAmount: 'مبلغ التأمين',
  typeOfInsurance: 'نوع التأمين',
  insurancePolicy: 'وثيقة التأمين',
  saveAsDraft: 'حفظ كمسودة',
  submit: 'إرسال',
  cancel: 'إلغاء',
  confirm: 'تأكيد',
  areYouSureDeleteAsset: 'هل أنت متأكد من أنك تريد حذف هذا الأصل؟',
  areYouSureSave: 'هل أنت متأكد من أنك تريد الحفظ؟',
  no: 'لا',
  yes: 'نعم',
};
const requestForm = {
  customerRequestInfo: 'معلومات طلب العميل',
  requestDate: 'تاريخ الطلب',
  WillBeGenerated: 'سيتم توليدها',
  serialNumber: 'الرقم التسلسلي',
  customerNumber: 'رقم العميل',
  branch: 'الفرع',
  companyName: 'اسم الشركة',
  tradeName: 'الاسم التجاري',
  typeOfRequest: 'نوع الطلب',
  typeOfFacility: 'نوع التسهيل',
  facilityDuration: 'مدة التسهيل',
  typeOfInstallment: 'نوع القسط',
  assetsInformation: 'معلومات الأصول',
  addAsset: 'إضافة أصل',
  atLeastOnePartner: 'يجب إدخال شريك واحد على الأقل!',
  assetToBeFinanced: 'الأصل المراد تمويله',
  assetType: 'نوع الأصل',
  assetName: 'اسم الأصل',
  assetDescription: 'وصف الأصل',
  assetCost: 'تكلفة الأصل',
  amountValue: 'قيمة المبلغ',
  downPaymentSecurity: 'دفعة مقدمة (هامش الأمان%)',
  downPayment: 'دفعة مقدمة (هامش الأمان%)',
  currency: 'العملة',
  loanAmount: 'مبلغ القرض',
  requestNumber: 'رقم الطلب',
  customerName: 'اسم العميل',
  requestStatus: 'حالة الطلب',
  loanType: 'نوع القرض',
  select: 'اختر',
  selectYear: 'اختر السنة',
  purposeOfFinance: 'الغرض من التمويل',
  assetCondition: 'حالة الأصل',
  dateOfOrigin: 'تاريخ المنشأ',
  organizationCountry: 'دولة المنشأة',
  supplierType: 'نوع المورد',
  depositorName: 'اسم المودع',
  assetManufacture: 'صناعة الأصل',
  ownershipDocumentationType: 'نوع وثائق الملكية',
  speedConversionToCash: 'سرعة التحويل إلى نقد',
  assetValueChanging: 'تغير قيمة الأصل',
  evaluationReportDate: 'تاريخ تقرير التقييم',
  evaluationReportRenewalDate: 'تاريخ تجديد تقرير التقييم',
  supplierInformation: 'معلومات المورد',
  supplierName: 'اسم المورد',
  assetDepreciation: 'استهلاك الأصل',
  insuranceDate: 'تاريخ التأمين',
  insuranceAmount: 'مبلغ التأمين',
  typeOfInsurance: 'نوع التأمين',
  insurancePolicy: 'وثيقة التأمين',
  saveAsDraft: 'حفظ كمسودة',
  submit: 'إرسال',
  cancel: 'إلغاء',
  confirm: 'تأكيد',
  areYouSureDeleteAsset: 'هل أنت متأكد أنك تريد حذف هذا الأصل؟',
  areYouSureSave: 'هل أنت متأكد أنك تريد الحفظ؟',
  no: 'لا',
  yes: 'نعم',
  // dropdowns
  directLeasing: 'التأجير المباشر',
  saleWithLeaseback: 'البيع مع إعادة التأجير',
  amend: 'تعديل',
  decrease: 'تخفيض',
  increase: 'زيادة',
  new: 'جديد',
  renewal: 'تجديد',
  reschedule: 'إعادة جدولة',
  writeOff: 'شطب',
  oneYear: 'سنة واحدة',
  twoYears: 'سنتين',
  threeYears: 'ثلاث سنوات',
  fourYears: 'أربع سنوات',
  fiveYears: 'خمس سنوات',
  sixYears: 'ست سنوات',
  sevenYears: 'سبع سنوات',
  eightYears: 'ثمان سنوات',
  nineYears: 'تسع سنوات',
  tenYears: 'عشر سنوات',
  monthlyInAdvanced: 'شهريًا مقدمًا',
  monthlyInArrears: 'شهريًا مؤخرًا',
  quarterlyInAdvanced: 'ربع سنويًا مقدمًا',
  quarterlyInArrears: 'ربع سنويًا مؤخرًا',
  semiAnnualInAdvanced: 'نصف سنويًا مقدمًا',
  semiAnnualInArrears: 'نصف سنويًا مؤخرًا',
};

const investigationFormPage = {
  summery: 'ملخص',
  scrollToTop: 'الانتقال إلى الأعلى',
  investigationForm: 'نموذج التحقيق',
  // Customer Information Tab
  customerInfo: 'معلومات العميل',
  totalInvestigation: 'إجمالي التحقيق',
  customerRequestInfo: 'معلومات طلب العميل',
  customerNumber: 'رقم العميل',
  requestDate: 'تاريخ الطلب',
  visitDate: 'تاريخ الزيارة',
  branch: 'الفرع',
  generalInformation: 'المعلومات العامة',
  activityData: 'بيانات النشاط',
  companyName: 'اسم الشركة',
  tradeName: 'الاسم التجاري',
  companyLegalStructure: 'الهيكل القانوني للشركة',
  amountOfCapital: 'مقدار رأس المال',
  lastCapitalIncreaseDate: 'تاريخ آخر زيادة لرأس المال',
  authorizedCapital: 'رأس المال المصرح به',
  capitalPaid: 'رأس المال المدفوع',
  issuedCapital: 'رأس المال المصدر',
  businessStartDate: 'تاريخ بدء الأعمال',
  numberOfBranches: 'عدد الفروع',
  sector: 'القطاع',
  industry: 'الصناعة',
  subIndustry: 'الصناعة الفرعية',
  commercialRegisterNumber: 'رقم السجل التجاري',
  realActivity: 'النشاط الحقيقي',
  lastRenewalDate: 'تاريخ آخر تجديد',
  licenseRenewalDate: 'تاريخ تجديد الترخيص',
  licenseNumber: 'رقم الترخيص',
  otherActivities: 'أنشطة أخرى',
  companyInformation: 'معلومات الشركة',
  governorate: 'المحافظة',
  directorate: 'المديرية',
  district: 'الحي',
  street: 'الشارع',
  neighbor: 'الجوار',
  headquarterOwnership: 'ملكية المقر الرئيسي',
  telephone: 'الهاتف',
  mobileNumber: 'رقم الهاتف المحمول',
  emailAddress: 'البريد الإلكتروني',
  responsibleRm: 'مدير العلاقات المسؤول',
  contactPerson: 'الشخص المسؤول',
  contactPersonEmailAddress: 'البريد الإلكتروني للشخص المسؤول',
  contactPersonNumber: 'رقم الشخص المسؤول',
  responsibleForManagement: 'المسؤول عن الإدارة',
  managerRelation: 'علاقة المدير',
  managerMobileNumber: 'رقم هاتف المدير المحمول',
  summary: 'الملخص',
  ownershipInformation: 'معلومات الملكية',
  ownerName: 'اسم المالك',
  percentage: 'النسبة المئوية',
  ownerPercentage: 'نسبة المالك',
  partnerBirthDate: 'تاريخ ميلاد الشريك',
  partnerAge: 'عمر الشريك',
  amount: 'المبلغ',
  ownerAmount: 'مبلغ المالك',
  educationLevel: 'المستوى التعليمي',
  yearsOfExperience: 'سنوات الخبرة',
  addPartner: 'إضافة شريك',
  atLeastOnePartner: 'يجب إدخال شريك واحد على الأقل!',
  saveAsDraft: 'حفظ كمسودة',
  confirmDelete: 'هل أنت متأكد أنك تريد حذف هذا الشريك؟',
  confirmSave: 'هل أنت متأكد أنك تريد الحفظ؟',
  // Management & Employees Tab
  managementAndEmployees: 'الإدارة والموظفين',
  management: 'الإدارة',
  yearsOfExperienceInFieldForOwners: 'كم عدد سنوات الخبرة في نفس المجال للمالكين؟',
  comment: 'تعليق',
  currentNumberOfEmployees: 'ما هو العدد الحالي للموظفين؟',
  permanentEmployeeNumber: 'عدد الموظفين الدائمين',
  seasonalEmployeeNumber: 'عدد الموظفين الموسميين',
  rightNumberOfWorkload: 'ما هو العدد الصحيح للعبء الوظيفي؟',
  staffTrained: 'هل الموظفين مدربون؟',
  yes: 'نعم',
  no: 'لا',
  technicalManagementExpertise: 'هل يوجد خبرة فنية وإدارية في فريق الإدارة؟',
  companyStructure: 'هل يوجد هيكل للشركة؟',
  futurePlans: 'هل لدى الشركة خطط مستقبلية للسنوات القادمة؟',
  keyPerson: 'من هو الشخص الرئيسي في المشروع؟',
  assetPosition: 'وضع الأصول',
  equipmentCount: 'كم عدد المعدات المستخدمة في الإنتاج؟',
  productionYearsOfEquipment: 'عدد سنوات إنتاج المعدات؟',
  equipmentMaintained: 'هل يتم صيانة المعدات؟',
  equipmentLastDateOfMaintenance: 'تاريخ آخر صيانة للمعدات',
  enoughEquipment: 'هل يوجد عدد كافٍ من المعدات لجميع الموظفين؟',
  equipmentUsedMoreThanNormal: 'هل تُستخدم هذه المعدات أكثر من المعتاد؟',
  currentProductionSize: 'حجم الإنتاج الحالي للمعدات؟',
  generalProductionSize: 'متوسط ​​الإنتاج العام للمعدات من نفس النوع؟',
  projectLocationAndHeadquarter: 'موقع المشروع والمقر الرئيسي',
  projectLocationCloseToCity: 'هل موقع المشروع قريب من المدينة؟',
  projectLocationAccessible: 'هل يمكن الوصول إليه بسهولة؟',
  projectLocationVisible: 'هل يمكن رؤية المشروع بسهولة على الطريق؟',
  similarProjectsInLocation: 'هل توجد مشاريع مماثلة في نفس الموقع؟',
  activeMarketForProjectActivity: 'هل يعتبر الموقع سوقًا نشطًا لنشاط المشروع؟',
  occupationalSafetyProblems: 'هل توجد مشاكل تتعلق بالسلامة المهنية؟',
  headquartersSizeAppropriate: 'هل حجم المقر الرئيسي مناسب أم كبير أو صغير جدًا للمطلوب؟',
  branchesExist: 'هل توجد فروع؟',
  branchCount: 'كم عدد الفروع؟',
  headquartersMaintained: 'هل يتم الحفاظ على المقر الرئيسي سواء كان ملكية أو إيجار؟',
  products: 'المنتجات',
  enoughRawMaterials: 'هل توجد مواد خام كافية؟',
  workProgress: 'هل يتم إحراز تقدم في العمل؟',
  goodsFinishAndSizeAppropriate: 'هل توجد بضائع تامة الصنع؟ (الحجم؟)',
  productCondition: 'هل المنتج في حالة جيدة أو ظروف الإمداد مناسبة لطبيعته؟',
  qualityControlReview: 'هل هناك رقابة كافية على العملية والمخزون من حيث السلامة والتفتيش والجودة؟',
  managementEmployeesSummary: 'ملخص الإدارة والموظفين',
  // Main Clients Tab
  mainClients: 'العملاء الرئيسيين',
  clientInfo: 'معلومات العميل',
  addClient: 'إضافة عميل',
  contactPersonName: 'اسم الشخص المسؤول',
  contactNumber: 'رقم الاتصال',
  address: 'العنوان',
  wayOfProcess: 'طريقة العملية',
  cash: 'نقدي',
  defer: 'تأجيل',
  feedback: 'ردود الفعل',
  // Main Suppliers Tab
  mainSuppliers: 'الموردون الرئيسيون',
  supplierInfo: 'معلومات المورد',
  addSupplier: 'إضافة مورد',
  // Tax and Insurance Status Tab
  taxAndInsuranceStatus: 'حالة الضرائب والتأمين',
  insuranceStatus: 'حالة التأمين',
  taxStatus: 'الحالة الضريبية',
  taxInsurenceSummery: 'ملخص حالة الضرائب والتأمين',
  // CBE Position Tab
  cbePosition: 'موقف البنك المركزي المصري',
  cbeCode: 'كود البنك المركزي المصري',
  cbeIndustryCode: 'كود الصناعة للبنك المركزي المصري',
  numberOfBanks: 'عدد البنوك',
  dateOfAcp: 'تاريخ ACP',
  facilityInfo: 'معلومات التسهيلات',
  addFacilityInfo: 'إضافة معلومات التسهيلات',
  facility: 'التسهيلات',
  creditFacility: 'تسهيلات ائتمانية',
  selectCreditFacility: 'اختيار',
  authorizedFacilities: 'التسهيلات المصرح بها',
  usedFacilities: 'التسهيلات المستخدمة',
  total: 'الإجمالي',
  totalAuthorizedFacilities: 'إجمالي التسهيلات المصرح بها',
  totalUsedFacilities: 'إجمالي التسهيلات المستخدمة',
  bankInfo: 'معلومات البنك',
  addBank: 'إضافة بنك',
  bankCode: 'كود البنك',
  bankName: 'اسم البنك',
  firstDealDate: 'تاريخ الصفقة الأولى',
  iscoreRating: 'تصنيف iScore',
  situationOfNegativeList: 'وضع القائمة السلبية',
  iscoreSummary: 'ملخص iScore',
  cbePositionSummery: 'ملخص موقف البنك المركزي المصري',
  // Mortgages Tab
  mortgages: 'الرهون',
  commercialRegister: 'السجل التجاري',
  commercialRegisterComment: 'تعليق السجل التجاري',
  cbe: 'البنك المركزي المصري',
  cbeComment: 'تعليق البنك المركزي المصري',
  morgtagesSummary: 'ملخص الرهون',
  ecrInformation: 'معلومات ECR',
  ecrNumber: 'رقم ECR',
  amountOfAssetsRegistered: 'قيمة الأصول المسجلة',
  dateOfRegistration: 'تاريخ التسجيل',
  descriptionOfAssetsRegistered: 'وصف الأصول المسجلة',
  ecrAmount: 'قيمة ECR',
  // Guarantess Tab
  guarantess: 'الضمانات',
  guarantorCompanyProvidingGuarantees: 'الضامن (الشركة التي تقدم الضمانات)',
  addGuarantor: 'إضافة ضامن',
  guarantorName: 'اسم الضامن',
  guarantorBank: 'بنك الضامن',
  guaranteeCompanyReceivingGuarantees: 'الضمان (الشركة التي تتلقى الضمانات)',
  addGuarantee: 'إضافة ضمان',
  guaranteeName: 'اسم الضمان',
  guaranteeBank: 'بنك الضمان',
  guarantorGuaranteeSummary: 'ملخص الضامن والضمان',
  // iScore Tab
  iscore: 'iScore',
  addNewIscore: 'إضافة iScore جديد',
  addIscore: 'إضافة iScore',
  facilityAmount: 'مبلغ التسهيل',
  currency: 'العملة',
  interestRate: 'معدل الفائدة',
  facilityType: 'نوع التسهيل',
  facilityDuration: 'مدة التسهيل',
  facilityPurpose: 'الغرض من التسهيل',
  facilityDate: 'تاريخ التسهيل',
  amountInArrears: 'مبلغ المتأخرات',
  recurrencesOfDelays: 'تكرار التأخير خلال فترة التسهيل',
  arrears: 'المتأخرات',
  status: 'الحالة',
  settlementDate: 'تاريخ التسوية',
  warranty: 'الضمان',
  typeOfGuarantee: 'نوع الضمان',
  totalFacilityAmount: 'إجمالي مبلغ التسهيل',
  name: 'الاسم',
  // Protesto and Bankruptcy Tab
  protestoAndBankruptcy: 'احتجاج والإفلاس',
  protestoComment: 'تعليق احتجاج',
  bankruptcyComment: 'تعليق الإفلاس',
  protestoBankruptcySummary: 'ملخص الاحتجاج والإفلاس',
  investigations: 'التحقيقات',
  noInvestigationReportFound: 'لم يتم العثور على تقرير التحقيق.',
  loadingInvestigationData: 'جاري تحميل بيانات التحقيق. الرجاء الانتظار.',
  serialNumber: 'الرقم التسلسلي',
  actionHeader: 'الإجراء',
  viewInvestigation: 'عرض التحقيق',
  startCreditReport: 'بدء تقرير الائتمان',
  editInvestigation: 'تعديل التحقيق',
  confirm: 'تأكيد',
  submit: 'إرسال',
  cancel: 'إلغاء',
};
const cashflowPage = {
  cashflow: 'التدفق المالي',
};
const facilitiesPage = {
  currentFacility: 'التسهيل الحالي',
  addCurrentFacility: 'إضافة التسهيل الحالي',
  facilityAmount: 'مبلغ التسهيل',
  facilityAmountPlaceholder: 'مبلغ التسهيل',
  currency: 'العملة',
  currencyPlaceholder: 'العملة',
  interestRate: 'معدل الفائدة',
  interestRatePlaceholder: 'معدل الفائدة',
  facilityType: 'نوع التسهيل',
  facilityTypePlaceholder: 'نوع التسهيل',
  facilityDuration: 'مدة التسهيل',
  facilityDurationPlaceholder: 'مدة التسهيل',
  gracePeriod: 'فترة السماح',
  gracePeriodPlaceholder: 'فترة السماح',
  facilityPurpose: 'غرض التسهيل',
  facilityPurposePlaceholder: 'غرض التسهيل',
  total: 'الإجمالي',
  totalPlaceholder: 'الإجمالي',
  previousFacilities: 'التسهيلات السابقة',
  addPreviousFacilities: 'إضافة التسهيلات السابقة',
  utilized: 'المستغل',
  utilizedPlaceholder: 'المستغل',
  facilityDate: 'تاريخ التسهيل',
  facilityDatePlaceholder: 'تاريخ التسهيل',
  arrears: 'المتأخرات',
  arrearsPlaceholder: 'المتأخرات',
  status: 'الحالة',
  statusPlaceholder: 'الحالة',
  settlementDate: 'تاريخ التسوية',
  settlementDatePlaceholder: 'تاريخ التسوية',
  guarantee: 'الضمان',
  guaranteePlaceholder: 'الضمان',
  cbePositionIscore: 'موقف البنك المركزي / التقييم الائتماني',
  addCbePositionIscore: 'إضافة موقف البنك المركزي / التقييم الائتماني',
  saveAsDraft: 'حفظ كمسودة',
  submit: 'إرسال',
  cancel: 'إلغاء',
  confirm: 'تأكيد',
  deleteConfirmation: 'هل أنت متأكد أنك تريد حذف هذا الأصل؟',
  saveConfirmation: 'هل أنت متأكد أنك تريد الحفظ؟',
  no: 'لا',
  yes: 'نعم',
};
const guaranteePage = {
  guarantee: 'الضمان',
  addGuarantor: 'إضافة ضامن',
  typeOfGuarantee: 'نوع الضمان',
  documentationOfWarrantyOwnership: 'توثيق ملكية الضمان',
  typeOfFacility: 'نوع المنشأة',
  coveringTheValueOfTheGuaranteeForFinancing: 'تغطية قيمة الضمان للتمويل',
  abilityToDisposeOfTheGuarantee: 'القدرة على التصرف في الضمان (سرعة تحويله إلى نقد)',
  extentToWhichValueOfGuaranteeChanged: 'مدى تغير قيمة الضمان',
  percentageAcceptedAsCollateral: 'النسبة المقبولة كضمان',
  marketValue: 'القيمة السوقية',
  netAcceptableValue: 'القيمة الصافية المقبولة',
  total: 'الإجمالي',
  facilityAmount: 'مبلغ المنشأة',
  facilityAmountPlaceholder: 'مبلغ المنشأة',
  uncoveredAmount: 'المبلغ غير المغطى',
  uncoveredAmountPlaceholder: 'المبلغ غير المغطى',
  coveredAmount: 'المبلغ المغطى',
  lgd: 'LGD',
  lgdPlaceholder: 'LGD',
  pd: 'PD',
  pdPlaceholder: 'PD',
  el: 'EL',
  elPlaceholder: 'EL',
  elPercentage: 'النسبة المئوية لـ EL',
  elPercentagePlaceholder: 'النسبة المئوية لـ EL',
  inCaseOfPersonalGuarantees: 'في حالة الضمانات الشخصية',
  atLeastOneGuarantorShouldBeEntered: 'يجب إدخال ضامن واحد على الأقل!',
  name: 'الاسم',
  tradeName: 'الاسم التجاري',
  activityType: 'نوع النشاط',
  activityDuration: 'مدة النشاط',
  activityAddressInDetail: 'عنوان النشاط بالتفصيل',
  commercialRegistrationNo: 'رقم السجل التجاري',
  homeAddress: 'عنوان السكن',
  workPlace: 'مكان العمل',
  workAddress: 'عنوان العمل',
  netSalary: 'الراتب الصافي',
  telephone: 'الهاتف',
  guarantorRelationshipWithClient: 'علاقة الضامن مع العميل',
  guarantorReputation: 'سمعة الضامن',
  saveAsDraft: 'حفظ كمسودة',
  submit: 'إرسال',
  cancel: 'إلغاء',
  confirm: 'تأكيد',
  deleteConfirmation: 'هل أنت متأكد أنك تريد حذف هذا الأصل؟',
  saveConfirmation: 'هل أنت متأكد أنك تريد الحفظ؟',
  no: 'لا',
  yes: 'نعم',
  select: 'إختر',
};
const projectionPage = {
  progressSpinnerAriaLabel: 'مؤشر التقدم المخصص',
  projectionFormTitle: 'نموذج الإسقاط',
  tabRepayment: 'السداد',
  tabProjectionAssumptions: 'افتراضات الإسقاط',
  tabLoanRunoff: 'نفاذ القرض',
  tabFixedAssetProjection: 'إسقاط الأصول الثابتة',
  yearsSetupDialogHeader: 'إعداد السنوات',
  addNewYearDialogHeader: 'إضافة سنة جديدة',
  startYearLabel: 'سنة البداية',
  selectYearPlaceholder: 'اختر السنة',
  periodsLabel: 'الفترات',
  periodsPlaceholder: 'الفترات',
  lastYearIsInterimLabel: 'السنة الأخيرة مؤقتة',
  yesLabel: 'نعم',
  noLabel: 'لا',
  endWithMonthLabel: 'تنتهي بالشهر',
  selectPlaceholder: 'اختر',
  addButtonLabel: 'إضافة',
  validationMessageStartYear: 'يرجى إدخال سنة بداية صحيحة.',
  validationMessagePeriods: 'يرجى إدخال عدد صحيح من الفترات.',
  warningMessageInterimYear: 'يرجى ملاحظة أن السنة الأخيرة التي تم إدخالها كانت مؤقتة، وتحتاج إلى تحديثها!',
  submit: 'إرسال',
  cancel: 'إلغاء',
  // Repayment Tap
  claimData: 'بيانات المطالبة',
  disbursementDate: 'تاريخ الصرف:',
  leaseDetails: 'تفاصيل الإيجار:',
  rentPeriod: 'مدة الإيجار:',
  leaseRentType: 'نوع إيجار الإيجار:',
  firstRentDue: 'الدفعة الأولى للإيجار:',
  assetCost: 'تكلفة الأصل:',
  percentOfFinance: 'نسبة التمويل %:',
  percentOfResidualValue: 'نسبة القيمة المتبقية %:',
  benchMark: 'المعيار %:',
  spread: 'الهامش %:',
  termInYears: 'المدة بالسنوات:',
  downPayment: 'الدفعة الأولى:',
  netFinancedAmount: 'المبلغ الممول الصافي:',
  residualValue: 'القيمة المتبقية:',
  decliningInterestRate: 'معدل الفائدة المتناقص:',
  rentAmount: 'مبلغ الإيجار:',
  totalRents: 'إجمالي الإيجارات:',
  grossFinancialIncome: 'الدخل المالي الإجمالي:',
  flatInterestRate: 'معدل الفائدة الثابت:',
  confirm: 'تأكيد',
  areYouSureYouWantToSave: 'هل أنت متأكد أنك تريد الحفظ؟',
  no: 'لا',
  yes: 'نعم',
  year: 'سنة',
  monthly: 'شهري',
  quarterly: 'ربع سنوي',
  semiAnnual: 'نصف سنوي',
  inArrear: 'في العائد',
  inAdvance: 'في السداد',
  // Loan Runoff tap
  loanTitle: 'تصفية القروض',
  normal: 'عادي',
  stress: 'ضغوط',
  saveConfirmation: 'هل أنت متأكد أنك تريد الحفظ؟',
  applyFirstValue: 'تطبيق القيمة الأولى على البقية',
  // Fixed Assets Tap
  fixedAssetTaptitle: 'توقعات الأصول الثابتة',
  // Projection Assumptions Tap
  normalScenario: 'السيناريو العادي',
  stressScenario: 'السيناريو الضاغط',
  title: 'العنوان',
};

const creditPage = {
  // Historical Page
  createFinancialInfo: 'إنشاء المعلومات المالية',
  createProjection: 'إنشاء التوقعات',
  createOrr: 'إنشاء ORR',
  guarantee: 'ضمان',
  facilities: 'المرافق',
  createCreditMemo: 'إنشاء مذكرة ائتمان',
  creditForm: 'استمارة ائتمان',
  addPeriod: 'إضافة فترة',
  yearsSetup: 'إعداد السنوات',
  startYear: 'سنة البداية',
  periods: 'الفترات',
  lastYearIsInterim: 'السنة الماضية هي مؤقتة',
  endWithMonth: 'انتهاء مع الشهر',
  add: 'إضافة',
  companyName: 'اسم الشركة',
  auditorName: 'اسم المدقق',
  cbeListedOrNot: 'مدرج في CBE أم لا',
  incomeStatement: 'بيان الدخل',
  assets: 'الأصول',
  liabEquity: 'الخصوم وحقوق الملكية',
  cashFlowStatement: 'بيان التدفقات النقدية',
  factSheet: 'ورقة الحقائق',
  cancel: 'إلغاء',
  submit: 'تقديم',
  // Income Statement Page
  incomeStatementTitle: 'بيان الدخل (EGP \'000s)',
  year: 'السنة',
  net_sales: 'صافي المبيعات',
  cost_of_goods_sold: 'تكلفة البضائع المباعة',
  depreciation: 'الاستهلاك',
  gross_profit: 'الربح الإجمالي',
  admin_depreciation: 'الاستهلاك الإداري',
  sales_general_admin_expense: 'مصاريف البيع العامة والإدارية',
  provisions: 'المخصصات',
  research_development_expenses: 'مصاريف البحث والتطوير',
  goodwill_amortization: 'استنفاد السمعة',
  other_amortization: 'استنفاد أخرى',
  net_operating_profit: 'صافي الربح التشغيلي',
  gross_interest_exp_exist: 'إجمالي مصروفات الفوائد القائمة',
  interest_expense_new: 'مصروفات الفوائد الجديدة',
  interest_income: 'إيرادات الفوائد',
  divided_income: 'إيرادات الأرباح',
  sundry_income_expense_fx_exchange: 'إيرادات / مصاريف متنوعة تبادل العملات',
  sundry_income_expense: 'إيرادات / مصاريف متنوعة',
  net_profit_before_tax: 'صافي الربح قبل الضريبة',
  total_tax_expense: 'إجمالي مصاريف الضرائب',
  current_tax_expense: 'مصاريف الضريبة الحالية',
  deferred_tax_expense: 'مصاريف الضريبة المؤجلة',
  net_profit_after_tax: 'صافي الربح بعد الضريبة',
  sale_of_plant: 'بيع المنشآت',
  other_unusual_item: 'عنصر غير عادي آخر',
  net_profit_after_unusual_items: 'صافي الربح بعد العناصر غير العادية',
  common_stock_dividends: 'أرباح الأسهم العادية',
  other_adjustment: 'تعديل آخر',
  total_change_in_re: 'إجمالي التغيير في الأرباح المحتجزة',
  // Assets Page
  assetsPageTitle: 'الأصول (EGP \'000s)',
  cash_and_cash_equivalent: 'النقد وما يعادله',
  marketable_securities: 'الأوراق المالية القابلة للتداول',
  account_receivables: 'الحسابات المدينة',
  allowances: 'المخصصات',
  net_account_receivables: 'صافي الحسابات المدينة',
  raw_materials: 'المواد الخام',
  work_in_progress: 'العمل قيد التقدم',
  finished_goods: 'السلع الجاهزة',
  goods_in_transit: 'السلع في الطريق',
  total_inventory: 'إجمالي المخزون',
  advance_payments_plus_prepaids: 'المدفوعات المقدمة بالإضافة إلى المدفوعات المسبقة',
  current_due_from_shareholders: 'المستحقات الحالية من المساهمين',
  other_current_asset: 'أصل متداول آخر',
  total_current_assets: 'إجمالي الأصول المتداولة',
  land: 'الأرض',
  building_and_improvement: 'المباني والتحسينات',
  equipment: 'المعدات',
  construction_in_progress: 'البناء قيد التقدم',
  other: 'آخر',
  gross_plant: 'المنشآت الإجمالية',
  less_accumulated_depreciation: 'ناقص الاستهلاك المتراكم',
  net_property_plant_equipment: 'صافي الممتلكات والمصانع والمعدات',
  investments: 'الاستثمارات',
  due_from_shareholders: 'المستحقات من المساهمين',
  other_tangible_asset: 'أصل ملموس آخر',
  deferred_tax_asset: 'أصل الضريبة المؤجلة',
  long_term_prepaids: 'المدفوعات المسبقة طويلة الأجل',
  total_other_tangible_assets: 'إجمالي الأصول الملموسة الأخرى',
  goodwill: 'السمعة',
  less_accum_amortization: 'ناقص الاستهلاك المتراكم',
  net_intangibles: 'الصافي غير الملموس',
  total_fixed_assets: 'إجمالي الأصول الثابتة',
  total_assets: 'إجمالي الأصول',
  // Liab & OE Page
  liabEquityPageTitle: 'الخصوم وحقوق الملكية (EGP \'000s)',
  short_term_borrowing_existing: 'الاقتراض قصير الأجل (قائم)',
  short_term_borrowing_new: 'الاقتراض قصير الأجل (جديد)',
  current_portion_senior_debt_existing: 'الجزء الحالي من الدين الكبير (قائم)',
  accounts_payable: 'الحسابات الدائنة',
  taxes_payable: 'الضرائب المستحقة',
  accrued_expenses: 'المصروفات المستحقة',
  interest_payable: 'الفوائد المستحقة',
  dividends_payable: 'الأرباح المستحقة',
  down_payments: 'المدفوعات المقدمة',
  due_to_sister_companies: 'المستحقات لشركات شقيقة',
  other_current_liability: 'خصم متداول آخر',
  total_current_liabilities: 'إجمالي الخصوم المتداولة',
  long_term_lease_obligation: 'التزام الإيجار طويل الأجل',
  long_term_lease_existing: 'الإيجار طويل الأجل (قائم)',
  long_term_senior_debt_new: 'الدين الكبير طويل الأجل (جديد)',
  long_term_sub_debt_existing: 'الدين الثانوي طويل الأجل (قائم)',
  long_term_sub_debt_new: 'الدين الثانوي طويل الأجل (جديد)',
  other_non_current_liabilities: 'خصوم غير متداولة أخرى',
  due_to_owners: 'المستحقات للمالكين',
  total_long_term_debt: 'إجمالي الديون طويلة الأجل',
  total_liabilities: 'إجمالي الخصوم',
  deferred_taxes: 'الضرائب المؤجلة',
  minority_interest: 'الأسهم غير المسيطرة',
  other_grey_area_item: 'عنصر منطقة رمادية أخرى',
  total_grey_area: 'إجمالي المنطقة الرمادية',
  preferred_stock: 'الأسهم الممتازة',
  common_stock: 'الأسهم العادية',
  additional_paid_in_capital: 'رأس المال المدفوع الإضافي',
  retained_earnings_opening: 'الأرباح المحتجزة (افتتاحية)',
  net_income: 'صافي الدخل',
  dividends: 'الأرباح',
  retained_earnings_closing: 'الأرباح المحتجزة (ختامية)',
  ongoing_owners_account: 'حساب المالكين المستمر',
  legal_reverse: 'التحويل القانوني',
  fx_adjustment: 'تعديل أسعار الصرف',
  other_equity_reverses: 'تحويلات أخرى لحقوق الملكية',
  total_equity: 'إجمالي حقوق الملكية',
  total_liab_and_equity: 'إجمالي الخصوم وحقوق الملكية',
  reconcile_bs: 'مطابقة الميزانية العمومية',
  new_money_need_short: 'التمويل الجديد المطلوب (قصير الأجل)',
  new_money_need_long: 'التمويل الجديد المطلوب (طويل الأجل)',
  // Cash Flow Statement Page
  cashFlowStatementPageTitle: 'بيان التدفقات النقدية (EGP \'000s)',
  refresh: 'تحديث',
  exportExcel: 'تصدير إلى Excel',
  npaui: 'Npaui',
  interest_expense: 'مصروفات الفوائد',
  gain_loss_on_sale_of_plant: 'الربح / الخسارة من بيع المنشآت',
  tax_payable: 'الضرائب المستحقة',
  tax_paid: 'الضرائب المدفوعة',
  nopat: 'Nopat',
  goodwill_and_other_amort: 'استنفاد السمعة وغيره',
  copat: 'Copat',
  change_in_account_receivables: 'التغير في الحسابات المدينة',
  change_in_inventory: 'التغير في المخزون',
  advance_payments_and_prepaids: 'المدفوعات المقدمة والمدفوعات المسبقة',
  change_in_account_payables: 'التغير في الحسابات الدائنة',
  change_accrued_expenses: 'التغير في المصروفات المستحقة',
  change_in_downpayments: 'التغير في المدفوعات المقدمة',
  net_change_in_working_investment: 'التغير الصافي في الاستثمار العامل',
  change_in_marketable_securities: 'التغير في الأوراق المالية القابلة للتداول',
  change_in_interest_payable: 'التغير في الفوائد المستحقة',
  other_current_liability_and_due_from_shareholders: 'الخصوم المتداولة الأخرى والمستحقات من المساهمين',
  cash_after_curr_operations: 'النقد بعد العمليات الحالية',
  interest_expense_neg: 'مصروفات الفوائد',
  current_portion_of_long_term_debt_existing: 'الجزء الحالي من الدين طويل الأجل (قائم)',
  short_term_loan: 'قرض قصير الأجل',
  total_financing_payments: 'إجمالي مدفوعات التمويل',
  cash_before_lt_uses: 'النقد قبل الاستخدامات طويلة الأجل',
  net_plant_expenditures: 'النفقات الصافية للمنشآت',
  interest_income_reverse: 'إيرادات الفوائد',
  divided_income_reverse: 'إيرادات الأرباح',
  sundry_income_expense_fx_exchange_reverse: 'إيرادات (مصاريف) متنوعة تبادل العملات',
  sundry_income_expense_reverse: 'إيرادات مصاريف متنوعة',
  gain_loss_on_sale_of_plant_reverse: 'الربح / الخسارة من بيع المنشآت',
  other_unusual_item_reverse: 'عنصر غير عادي آخر',
  cash_before_financing: 'النقد قبل التمويل',
  change_in_short_term_debt: 'التغير في الديون قصيرة الأجل',
  change_in_long_term_debt: 'التغير في الديون طويلة الأجل',
  change_in_lease: 'التغير في الإيجار',
  change_in_equity: 'التغير في حقوق الملكية',
  net_cash_flow: 'صافي التدفق النقدي',
  cads: 'Cads',
  ds: 'Ds',
  nopat_financing_payments: 'مدفوعات التمويل Nopat',
  copat_financing_payments: 'مدفوعات التمويل Copat',
  cads_ds: 'Cads Ds',
  // Fact Sheet Page
  factSheetPageTitle: 'ورقة الحقائق',
  depont_formula: 'DUPONT FORMULA',
  profitability: 'الربحية',
  asset_efficiency: 'كفاءة الأصول',
  liquidity_and_leverage: 'السيولة والرافعة المالية',
  ret_on_sales: 'العائد على المبيعات Npat/المبيعات',
  asset_util: 'استخدام الأصول. مبيعات/تا',
  total_assets_equity_ratio: 'إجمالي الأصول / حقوق الملكية',
  return_on_equity: 'العائد على حقوق الملكية',
  return_on_assets: 'العائد على الأصول',
  sales_growth_rate: 'معدل نمو المبيعات',
  cogs_sales_ratio: 'تكلفة البضائع المباعة / المبيعات',
  gross_profit_sales_ratio: 'الربح الإجمالي / المبيعات',
  sg_and_a_sales_ratio: 'المصروفات الإدارية وبيع / المبيعات',
  provisions_sales_ratio: 'المخصصات / المبيعات',
  research_and_development_expenses_sales_ratio: 'مصاريف البحث والتطوير / المبيعات',
  operating_profit_margin: 'هامش الربح التشغيلي',
  net_profit_before_tax_sales_ratio: 'صافي الربح قبل الضريبة / المبيعات',
  net_profit_after_tax_sales_ratio: 'صافي الربح بعد الضريبة / المبيعات',
  net_profit_after_unusual_items_sales_ratio: 'صافي الربح بعد العناصر غير العادية Npaui / المبيعات',
  working_investment: 'الاستثمار العامل',
  change_in_working_investment: '% التغير في الاستثمار العامل',
  working_investment_revenue_ratio: 'الاستثمار العامل / الإيرادات',
  account_receivables_days_on_hand: 'أيام الحسابات المدينة على اليد',
  account_payable_days_on_hand: 'أيام الحسابات الدائنة على اليد',
  total_inventory_days: 'إجمالي أيام المخزون',
  raw_materials_inv_days: 'أيام مخزون المواد الخام',
  wip_inv_days: 'أيام مخزون العمل قيد التقدم',
  fg_inv_days: 'أيام مخزون السلع الجاهزة',
  accrued_exp_days: 'أيام المصروفات المستحقة',
  down_payments_doh: 'المدفوعات المقدمة Doh',
  advance_payments_doh: 'المدفوعات المقدمة Doh',
  acc: 'ACC',
  current_ratio: 'النسبة الجارية',
  net_working_capital: 'صافي رأس المال العامل',
  quick_ratio: 'النسبة السريعة',
  coverage_ratio: 'نسبة التغطية Ca/Tl',
  total_liabilities_net_worth_ratio: 'إجمالي الخصوم / صافي الثروة',
  interest_coverage_ebit: 'تغطية الفوائد: Ebit',
  debt_assets_ratio: 'نسبة الدين إلى الأصول',
  // end of Fact Sheet Page
  noInvestigationReportFound: 'لم يتم العثور على تقرير التحقيق.',
  loadingInvestigationData: 'جارٍ تحميل بيانات التحقيق. يرجى الانتظار.',
  serialNumber: 'رقم التسلسل',
  tradeName: 'الاسم التجاري',
  actionHeader: 'الإجراء',
  requiredDocuments: 'المستندات المطلوبة',
  viewInvestigation: 'عرض التحقيق',
  viewCreditHistoricalDetail: 'عرض تفاصيل الائتمان التاريخية',
  startCreditMemo: 'بدء مذكرة الائتمان',
  creditMemoNo: 'رقم مذكرة الائتمان',
  authorityOfApproval: 'المسؤول عن الموافقة',
  approvalDate: 'تاريخ الموافقة',
  putposeOfMemo: 'غرض مذكرة الإتمان',
  creditMemo: 'مذكرة الائتمان',
};
const riskPage = {
  noCreditReportFound: 'لم يتم العثور على تقرير الائتمان.',
  loadingCreditData: 'جاري تحميل بيانات الائتمان. الرجاء الانتظار.',
  companyName: 'اسم الشركة',
  serialNumber: 'الرقم التسلسلي',
  actionHeader: 'الإجراء',
  tradeName: 'الاسم التجاري',
  requiredDocuments: 'المستندات المطلوبة',
  viewInvestigation: 'عرض التحقيق',
  viewCreditHistoricalDetail: 'عرض التفاصيل التاريخية للائتمان',
  viewProjection: 'عرض التوقعات',
  startCreditRiskMemo: 'بدء مذكرة مخاطر الائتمان',
  // Repayment Page
  claimData: 'بيانات المطالبة',
  leaseDetails: 'تفاصيل الإيجار',
  disbursementDate: 'تاريخ الصرف',
  rentPeriod: 'فترة الإيجار',
  leaseRentType: 'نوع إيجار الإيجار',
  firstRentDue: 'تاريخ استحقاق الإيجار الأول',
  assetCost: 'تكلفة الأصل',
  percentOfFinance: 'نسبة التمويل %',
  percentOfResidualValue: 'نسبة القيمة المتبقية %',
  benchMark: 'المعيار %',
  spread: 'الفرق %',
  termInYears: 'المدة بالسنوات',
  downPayment: 'الدفع الأولي',
  netFinancedAmount: 'صافي المبلغ الممول',
  residualValue: 'القيمة المتبقية',
  decliningInterestRate: 'معدل الفائدة المتناقص',
  rentAmount: 'مبلغ الإيجار',
  totalRents: 'إجمالي الإيجارات',
  grossFinancialIncome: 'الدخل المالي الإجمالي',
  flatInterestRate: 'معدل الفائدة الثابت',
  confirm: 'تأكيد',
  areYouSureYouWantToSave: 'هل أنت متأكد أنك تريد الحفظ؟',
  no: 'لا',
  yes: 'نعم',
  submit: 'إرسال',
  cancel: 'إلغاء',
  // Projection Assumption Table
  userType: 'نوع المستخدم',
  credit: 'ائتمان',
  admin: 'مسؤول',
  risk: 'مخاطر',
  normalScenario: 'السيناريو العادي',
  title: 'العنوان',
  normal: 'عادي',
  stressScenario: 'سيناريو الضغط',
  stress: 'ضغط',
  // Loan RunOff Table
  loanRunoff: 'تصفية القرض',
  applyFirstValueToRest: 'تطبيق القيمة الأولى على الباقي',
  loanInputNormal: 'إدخال القرض العادي',
  loanInputStress: 'إدخال القرض تحت الضغط',
  // Projection Assumption Table
  mainTitleNormalScenario: 'السيناريو العادي',
  mainTitleStressScenario: 'سيناريو الضغط',
  mainTitleLoanRunoff: 'تصفية القرض',
  confirmationContent: 'هل أنت متأكد أنك تريد الحفظ؟',
  confirmationDialogHeader: 'تأكيد',
  buttonNo: 'لا',
  buttonYes: 'نعم',
  buttonSubmit: 'إرسال',
  buttonCancel: 'إلغاء',
  buttonApplyFirstValueToRest: 'تطبيق القيمة الأولى على الباقي',
};
const riskMemoForm = {
  creditRiskForm: 'نموذج المخاطر الائتمانية',
  creditMemoNo: 'رقم مذكرة الائتمان',
  authorityOfApproval: 'سلطة الموافقة',
  approvalDate: 'تاريخ الموافقة',
  putposeOfMemo: 'غرض المذكرة',
  saveAsDraft: 'حفظ كمسودة',
  submit: 'إرسال',
  cancel: 'إلغاء',
  confirm: 'تأكيد',
  deleteConfirmation: 'هل أنت متأكد أنك تريد حذف هذا العنصر؟',
  saveConfirmation: 'هل أنت متأكد أنك تريد الحفظ؟',
  no: 'لا',
  yes: 'نعم',
  // Company & Request information Tab
  companyAndRequestInformation: 'معلومات الشركة والطلب',
  clientInformation: 'معلومات العميل',
  date: 'التاريخ',
  automaticGeneration: 'توليد تلقائي',
  companyName: 'اسم الشركة',
  tradeName: 'الاسم التجاري',
  relationshipSince: 'العلاقة منذ',
  selectYear: 'اختر السنة',
  address: 'العنوان',
  governorate: 'المحافظة',
  select: 'اختر',
  ownershipOfBusinessBuilding: 'ملكية مبنى العمل',
  legalForm: 'الشكل القانوني',
  sector: 'القطاع',
  industry: 'الصناعة',
  subIndustry: 'الصناعة الفرعية',
  realActivity: 'النشاط الفعلي',
  classificationInFi: 'التصنيف في المؤسسة المالية (للعملاء الحاليين فقط)',
  applicationNumber: 'رقم الطلب',
  branch: 'الفرع',
  businessStartingDate: 'تاريخ بدء العمل',
  authorizedCapital: 'رأس المال المصرح به',
  paidInCapital: 'رأس المال المدفوع',
  commercialRegisterNumber: 'رقم السجل التجاري',
  commercialRegisterDate: 'تاريخ السجل التجاري',
  commercialRegisterRenewalDate: 'تاريخ تجديد السجل التجاري',
  operationalLicenseNumber: 'رقم الترخيص التشغيلي',
  endDateOfLicense: 'تاريخ انتهاء الترخيص',
  numberOfEmployees: 'عدد الموظفين',
  applicationDate: 'تاريخ الطلب',
  orrMoodysRatingCurrent: 'التصنيف الحالي لوكالة Moody’s',
  orrMoodysRatingExpected: 'التصنيف المتوقع لوكالة Moody’s',

  // Facility Structure Tab
  facilityStructure: 'هيكل المنشأة',
  facilityInformation: 'معلومات المنشأة',
  currentFacility: 'المنشأة الحالية',
  currentLimits: 'الحدود الحالية',
  outstanding: 'المتبقي',
  listOfFacilities: 'قائمة المنشآت',
  totalLimit: 'الحد الإجمالي',
  purposeClarification: 'توضيح الغرض',
  pricingPercentage: 'النسبة المئوية للتسعير',
  pricing: 'التسعير',
  proposedFeesPercentage: 'النسبة المئوية للرسوم المقترحة',
  proposedFees: 'الرسوم المقترحة',
  drawDownPeriod: 'فترة السحب',
  downPayment: 'الدفع المسبق',
  tenor: 'المدة',
  expiryDate: 'تاريخ الانتهاء',
  sourceOfRepayment: 'مصدر السداد',
  collateral: 'الضمانات',
  guarantees: 'الضمانات',
  dealMechanism: 'آلية الصفقة',
  financedAssetsDetails: 'تفاصيل الأصول الممولة',
  assetToBeFinanced: 'الأصل المراد تمويله',
  assetType: 'نوع الأصل',
  assetDescription: 'وصف الأصل',
  originationCountry: 'بلد المنشأ',
  assetCondition: 'حالة الأصل',
  profitability: 'الربحية',
  specialTerms: 'الشروط الخاصة',
  specialTerm: 'شرط خاص',
  atLeastOneTermShouldBeEntered: 'يجب إدخال شرط واحد على الأقل!',
  newTermInfo: 'معلومات الشروط الجديدة',
  addSpecialTerm: 'إضافة شرط خاص',
  noTermsToShow: 'لا توجد شروط لعرضها',
  noTermsAddedYet: 'لم تتم إضافة أي شروط بعد',
  totalFacilityAmount: 'إجمالي مبلغ المنشأة',
  // Company’s profile and background Tab
  companyProfileAndBackground: 'ملف الشركة والخلفية',
  dealInformation: 'معلومات الصفقة',
  companyProfileBackground: 'ملف الشركة والخلفية',
  shareholdersStructure: 'هيكل المساهمين',
  namesOfPartnersShareholders: 'أسماء الشركاء / المساهمين',
  title: 'اللقب',
  percentageOfOwnership: 'نسبة الملكية',
  amount: 'المبلغ',
  educationalLevel: 'المستوى التعليمي',
  yearsOfExperience: 'سنوات الخبرة',
  ownerBirthdate: 'تاريخ ميلاد المالك',
  ownerAge: 'عمر المالك',
  // Investigation Summary Tab
  industryAnalysisLabel: 'تحليل الصناعة',
  // Financial Analysis Tab
  financialAnalysis: 'التحليل المالي',
  mainFinancialIndicator: 'المؤشر المالي الرئيسي',
  salesAndProfitability: 'المبيعات والربحية',
  salesAndProfitabilityComments: 'تعليقات المبيعات والربحية',
  assetsEfficiency: 'كفاءة الأصول',
  assetsEfficiencyComments: 'تعليقات كفاءة الأصول',
  liquidityLeverageAndCapitalStructure: 'السيولة، الرافعة وهيكل رأس المال',
  liquidityLeverageAndCapitalStructureComments: 'تعليقات السيولة، الرافعة وهيكل رأس المال',
  cashflow: 'التدفقات النقدية',
  projectionTable: 'جدول التوقعات',
  projectionComments: 'تعليقات التوقعات',
  normal: 'طبيعي',
  ok: 'موافق',
  investigationSummaryTabHeader: 'ملخص التحقيق',
  summaryOfInvestigationReportHeader: 'ملخص تقرير التحقيق حول العميل',
  reputationInMarketLabel: 'السمعة في السوق',
  situationWithBanksLabel: 'الوضع مع البنوك',
  bankruptcySituationLabel: 'الوضع الإفلاسي',
  taxSituationLabel: 'الوضع الضريبي',
  insuranceSituationLabel: 'الوضع التأميني',
  protestSituationLabel: 'وضع الاعتراض',
  investigationCommentLabel: 'تعليق التحقيق',
  creditCommentLabel: 'تعليق الائتمان',
  iscoreReportHeader: 'تقرير i-Score',
  iscoreNameHeader: 'الاسم',
  iscoreNumberOfBanksHeader: 'عدد البنوك',
  iscoreRatingHeader: 'تصنيف i-Score',
  iscoreSituationOfNegativeListHeader: 'وضع القائمة السلبية',
  iscoreCommentsPlaceholder: 'تعليقات i-Score',
  positivePoints: 'النقاط الإيجابية',
  negativePoints: 'النقاط السلبية',
  cbePositionHeader: 'موقف البنك المركزي',
  cbeTypeOfLoanHeader: 'نوع القرض',
  cbeAuthorizedHeader: 'المصرح به',
  cbeOutstandingHeader: 'المتبقي',
  cbePercentageHeader: 'النسبة المئوية',
  totalLabel: 'الإجمالي',
  totalAuthorizedFacilitiesPlaceholder: 'إجمالي المنشآت المصرح بها',
  totalUsedFacilitiesPlaceholder: 'إجمالي المنشآت المستخدمة',
  operationalCycleHeader: 'دورة التشغيل',
  operationalCycleLabel: 'دورة التشغيل',
  keyCustomersHeader: 'العملاء الرئيسيون',
  keyCustomersNameHeader: 'الاسم',
  keyCustomersTypeHeader: 'نوع العميل',
  keyCustomersSalesHeader: 'نسبة المبيعات',
  keyCustomersPaymentTermsHeader: 'شروط الدفع (العملاء الرئيسيون)',
  keyCustomersLocalHeader: 'محلي',
  keyCustomersExportHeader: 'تصدير',
  keyCustomersSalesPercentageHeader: 'نسبة المبيعات',
  keySuppliersHeader: 'الموردون الرئيسيون',
  keySuppliersNameHeader: 'الاسم',
  keySuppliersTypeHeader: 'نوع المورد',
  keySuppliersPurchasesHeader: 'نسبة المشتريات',
  keySuppliersPaymentTermsHeader: 'شروط الدفع (الموردون الرئيسيون)',
  keySuppliersLocalHeader: 'محلي',
  keySuppliersExportHeader: 'تصدير',
  keySuppliersPurchasesPercentageHeader: 'نسبة المشتريات',
  inventoryPolicyHeader: 'سياسة المخزون',
  inventoryPolicyLabel: 'سياسة المخزون',
  negative: 'سلبي',
  positive: 'إيجابي',
  numberOfBanks: 'عدد البنوك',
  // Recommendations Tab
  recommendationsTabHeader: 'التوصيات',
  recommendationsLabel: 'التوصيات',
  nameHeader: 'الاسم',
  titleHeader: 'اللقب',
  decisionHeader: 'القرار',
  commentsHeader: 'التعليقات',
  actionHeader: 'الإجراء',
  creditMemoTeamRecommendationsHeader: 'توصيات فريق مذكرة الائتمان',
  // Portfolio Analysis Tab
  portfolioAnalysis: 'تحليل المحفظة',
  concentrationPerIndustry: 'التركيز حسب الصناعة',
  numberOfClientsWithinSameIndsutry: 'عدد العملاء في نفس الصناعة',
  perfromingWithinSameIndsutry: 'نسبة عدم الأداء في نفس الصناعة',
  averagePricingForSameIndustry: 'متوسط التسعير لنفس الصناعة',
  // Conclusion Tab
  conclusion: 'الخلاصة',
  conclusions: 'الخلاصات',
  // cashFlowTitles
  cash_after_curr_operations: 'النقد بعد العمليات الحالية',
  cash_before_financing: 'النقد قبل التمويل',
  net_cash_flow: 'صافي التدفق النقدي',
  nopat_financing_payments: 'الربح التشغيلي الطبيعي بعد الضرائب (NOPAT)',
  copat_financing_payments: 'الربح التشغيلي الحالي بعد الضرائب (COPAT)',
  // Main Financial Indicator Table
  net_sales: 'المبيعات',
  cogs_sales_ratio: 'نسبة تكلفة البضائع المباعة (COGS) / المبيعات (%)',
  sales_growth_rate: 'معدل نمو المبيعات (%)',
  gross_profit_sales_ratio: 'هامش الربح الإجمالي %',
  net_profit_after_tax_sales_ratio: 'هامش الربح الصافي %',
  net_profit_after_tax: 'الربح الصافي',
  current_ratio: 'نسبة السيولة الحالية',
  total_liabilities_net_worth_ratio: 'الرافعة المالية',
  return_on_equity: 'العائد على حقوق الملكية',
  return_on_assets: 'العائد على الأصول',
  total_inventory_days: 'أيام المخزون',
  account_receivables_days_on_hand: 'أيام الذمم المدينة',
  account_payable_days_on_hand: 'أيام الذمم الدائنة',
};

const creditMemoPage = {
  // Company & Request information Tab
  companyAndRequestInformation: 'معلومات الشركة والطلب',
  clientInformation: 'معلومات العميل',
  date: 'التاريخ',
  automaticGeneration: 'توليد تلقائي',
  companyName: 'اسم الشركة',
  tradeName: 'الاسم التجاري',
  relationshipSince: 'العلاقة منذ',
  selectYear: 'اختر السنة',
  address: 'العنوان',
  governorate: 'المحافظة',
  select: 'اختر',
  ownershipOfBusinessBuilding: 'ملكية المبنى التجاري',
  legalForm: 'الهيئة القانونية',
  sector: 'القطاع',
  industry: 'الصناعة',
  subIndustry: 'الصناعة الفرعية',
  realActivity: 'النشاط الفعلي',
  classificationInFi: 'التصنيف في FI. العملاء الحاليين فقط',
  applicationNumber: 'رقم الطلب',
  branch: 'الفرع',
  businessStartingDate: 'تاريخ بدء العمل',
  authorizedCapital: 'رأس المال المصرح به',
  paidInCapital: 'رأس المال المدفوع',
  commercialRegisterNumber: 'رقم السجل التجاري',
  commercialRegisterDate: 'تاريخ السجل التجاري',
  commercialRegisterRenewalDate: 'تاريخ تجديد السجل التجاري',
  operationalLicenseNumber: 'رقم الترخيص التشغيلي',
  endDateOfLicense: 'تاريخ انتهاء الترخيص',
  numberOfEmployees: 'عدد الموظفين',
  applicationDate: 'تاريخ الطلب',
  orrMoodysRatingCurrent: 'تصنيف ORR من Moody’s الحالي',
  orrMoodysRatingExpected: 'تصنيف ORR من Moody’s المتوقع',

  // Facility Structure Tab
  facilityStructure: 'هيكل المنشأة',
  facilityInformation: 'معلومات المنشأة',
  currentFacility: 'المنشأة الحالية',
  currentLimits: 'الحدود الحالية',
  outstanding: 'المتبقي',
  listOfFacilities: 'قائمة المنشآت',
  totalLimit: 'الحد الأقصى الإجمالي',
  purposeClarification: 'توضيح الهدف',
  pricingPercentage: 'نسبة التسعير',
  pricing: 'التسعير',
  proposedFeesPercentage: 'نسبة الرسوم المقترحة',
  proposedFees: 'الرسوم المقترحة',
  drawDownPeriod: 'فترة السحب',
  downPayment: 'الدفعة الأولى',
  tenor: 'مدة القرض',
  expiryDate: 'تاريخ الانتهاء',
  sourceOfRepayment: 'مصدر السداد',
  collateral: 'الضمانات',
  guarantees: 'الضمانات',
  dealMechanism: 'آلية الصفقة',
  financedAssetsDetails: 'تفاصيل الأصول الممولة',
  assetToBeFinanced: 'الأصل الذي سيتم تمويله',
  assetType: 'نوع الأصل',
  assetDescription: 'وصف الأصل',
  originationCountry: 'بلد المنشأ',
  assetCondition: 'حالة الأصل',
  profitability: 'الربحية',
  specialTerms: 'شروط خاصة',
  atLeastOneTermShouldBeEntered: 'يجب إدخال شرط واحد على الأقل!',
  newTermInfo: 'معلومات الشرط الجديد',
  addSpecialTerm: 'إضافة شرط خاص',
  noTermsToShow: 'لا توجد شروط لعرضها',
  noTermsAddedYet: 'لم يتم إضافة أي شروط بعد',
  totalFacilityAmount: 'المبلغ الأجمالي للمنشأة',

  // Company’s profile and background Tab
  companyProfileAndBackground: 'ملف الشركة والخلفية',
  dealInformation: 'معلومات الصفقة',
  companyProfileBackground: 'ملف الشركة والخلفية',
  shareholdersStructure: 'هيكل المساهمين',
  namesOfPartnersShareholders: 'أسماء الشركاء / المساهمين',
  title: 'اللقب',
  percentageOfOwnership: 'نسبة الملكية',
  amount: 'المبلغ',
  educationalLevel: 'المستوى التعليمي',
  yearsOfExperience: 'سنوات الخبرة',
  ownerBirthdate: 'تاريخ ميلاد المالك',
  ownerAge: 'عمر المالك',

  // Investigation Summary Tab
  industryAnalysisLabel: 'تحليل الصناعة',

  // Financial Analysis Tab
  financialAnalysis: 'تحليل المالية',
  salesAndProfitability: 'المبيعات والربحية',
  salesAndProfitabilityComments: 'تعليقات المبيعات والربحية',
  assetsEfficiency: 'الأداء الأصولي',
  assetsEfficiencyComments: 'تعليقات الأداء الأصولي',
  liquidityLeverageAndCapitalStructure: 'المعادن والتراكم والهيكل الأساسي',
  liquidityLeverageAndCapitalStructureComments: 'تعليقات المعادن والتراكم والهيكل الأساسي',
  cashflow: 'التدفق النقدي',
  projectionTable: 'جدول التمثيل',
  projectionComments: 'تعليقات التمثيل',
  normal: 'طبيعي',
  submit: 'إرسال',
  cancel: 'الغاء',
  confirm: 'تأكيد',
  saveAsDraft: 'حفظ كمسودة',
  confirmDelete: 'هل تريد حذف هذا العنصر؟',
  confirmSave: 'هل تريد حفظ هذا العنصر؟',
  no: 'لا',
  yes: '',
  ok: 'موافق',
  investigationSummaryTabHeader: 'ملخص التحقيق',
  summaryOfInvestigationReportHeader: 'ملخص تقرير التحقيق عن العميل',
  reputationInMarketLabel: 'السمعة في السوق',
  situationWithBanksLabel: 'الوضع مع البنوك',
  bankruptcySituationLabel: 'وضع الإفلاس',
  taxSituationLabel: 'الوضع الضريبي',
  insuranceSituationLabel: 'الوضع التأميني',
  protestSituationLabel: 'وضع الاحتجاج',
  investigationCommentLabel: 'تعليق التحقيق',
  creditCommentLabel: 'تعليق الائتمان',
  iscoreReportHeader: 'تقرير i-Score',
  iscoreNameHeader: 'الاسم',
  iscoreNumberOfBanksHeader: 'عدد البنوك',
  iscoreRatingHeader: 'تصنيف i-Score',
  iscoreSituationOfNegativeListHeader: 'وضع القائمة السلبية',
  iscoreCommentsPlaceholder: 'تعليقات iScore',
  cbePositionHeader: 'موقع CBE',
  cbeTypeOfLoanHeader: 'نوع القرض',
  cbeAuthorizedHeader: 'مصادق عليه',
  cbeOutstandingHeader: 'المتبقي',
  cbePercentageHeader: 'النسبة المئوية',
  totalLabel: 'الإجمالي',
  totalAuthorizedFacilitiesPlaceholder: 'إجمالي المنشآت المصرح بها',
  totalUsedFacilitiesPlaceholder: 'إجمالي المنشآت المستخدمة',
  operationalCycleHeader: 'الدورة التشغيلية',
  operationalCycleLabel: 'الدورة التشغيلية',
  keyCustomersHeader: 'العملاء الرئيسيون',
  keyCustomersNameHeader: 'الاسم',
  keyCustomersTypeHeader: 'نوع العميل',
  keyCustomersSalesHeader: 'نسبة المبيعات',
  keyCustomersPaymentTermsHeader: 'شروط الدفع و الأحكام (العملاء الرئيسيون)',
  keyCustomersLocalHeader: 'محلي',
  keyCustomersExportHeader: 'تصدير',
  keyCustomersSalesPercentageHeader: 'نسبة المبيعات',
  keySuppliersHeader: 'الموردون الرئيسيون',
  keySuppliersNameHeader: 'الاسم',
  keySuppliersTypeHeader: 'نوع المورد',
  keySuppliersPurchasesHeader: 'نسبة المشتريات',
  keySuppliersPaymentTermsHeader: 'شروط الدفع و الأحكام (الموردون الرئيسيون)',
  keySuppliersLocalHeader: 'محلي',
  keySuppliersExportHeader: 'تصدير',
  keySuppliersPurchasesPercentageHeader: 'نسبة المشتريات',
  inventoryPolicyHeader: 'سياسة المخزون',
  inventoryPolicyLabel: 'سياسة المخزون',
  negative: 'سلبي',
  positive: 'ايجابي',
  numberOfBanks: 'عدد البنوك',
  // Recommendations Tab
  recommendationsTabHeader: 'التوصيات',
  recommendationsLabel: 'التوصيات',
  nameHeader: 'الاسم',
  titleHeader: 'اللقب',
  decisionHeader: 'القرار',
  commentsHeader: 'التعليقات',
  actionHeader: 'الإجراء',
  creditMemoTeamRecommendationsHeader: 'توصيات فريق مذكرة الائتمان',

  // New Translation
  companyRequestInfo: 'معلومات الشركة والطلب',
  // Sales And Profitability Table
  net_sales: 'المبيعات',
  sales_growth_rate: 'معدل نمو المبيعات (%)',
  cogs_sales_ratio: 'تكلفة البضائع المباعة (COGS)/المبيعات (%)',
  gross_profit: 'إجمالي الربح',
  gross_profit_sales_ratio: 'هامش الربح الإجمالي %',
  sg_and_a_sales_ratio: 'المصروفات العامة والإدارية والمبيعات',
  net_profit_after_tax: 'صافي الربح',
  net_profit_after_tax_sales_ratio: 'هامش صافي الربح %',
  total_equity: 'إجمالي حقوق الملكية',
  return_on_equity: 'العائد على حقوق الملكية',
  // Assets’ Efficiency Table
  working_investment: 'الاستثمار العامل (ألف جنيه مصري)',
  working_investment_revenue_ratio: 'الاستثمار العامل / المبيعات %',
  account_receivables_days_on_hand: 'أيام الحسابات المدينة على اليد',
  total_inventory_days: 'أيام المخزون على اليد',
  account_payable_days_on_hand: 'أيام الحسابات الدائنة على اليد',
  total_assets: 'إجمالي الأصول',
  return_on_assets: 'العائد على الأصول',
  // Liquidity, Leverage & Capital Structure Table
  current_ratio: 'النسبة الجارية',
  net_working_capital: 'صافي رأس المال العامل',
  total_liabilities_net_worth_ratio: 'الرفع المالي',
  short_term_debt: '% الديون قصيرة الأجل',
  spontaneous_financing: '% التمويل التلقائي',
  long_term_debt: '% الديون طويلة الأجل',
  net_worth: '% صافي الثروة',
  other_liabilities: '% خصوم أخرى',
  other_grey_area: '% أخرى (منطقة رمادية)',
  // CashFlow Table
  cash_after_curr_operations: 'النقد بعد العمليات الحالية',
  cash_before_financing: 'النقد قبل التمويل',
  net_cash_flow: 'صافي التدفق النقدي',
  nopat_financing_payments: 'الأرباح التشغيلية المعدلة بعد الضريبة (NOPAT)',
  copat_financing_payments: 'الأرباح التشغيلية الحالية بعد الضريبة (COPAT)',
};
const headerCards = {
  edit: 'تعديل',
  create: 'اضافة',
  financialInfo: 'معلومات مالية',
  projection: 'التمثيل',
  orr: 'ORR',
  gurantee: 'الضمان',
  facilities: 'المرافق',
  creditMemo: 'مذكرة الائتمان',
};
const months = {
  Jan: 'يناير',
  Feb: 'فبراير',
  Mar: 'مارس',
  Apr: 'ابريل',
  May: 'مايو',
  Jun: 'يونيو',
  Jul: 'يوليو',
  Aug: 'اغسطس',
  Sep: 'سبتمبر',
  Oct: 'اكتوبر',
  Nov: 'نوفمبر',
  Dec: 'ديسمبر',
};
const messages = {
  yourMessage: 'رسالتك',
  customers: 'العملاء',
  totalCustomers: 'جميع العملاء',
  noData: 'لا يوجد بيانات',
  loading: 'جاري التحميل...',
  error: 'خطأ',
  success: 'بنجاح',
  required: 'هذا الحقل مطلوب',
  searchPlaceholder: 'كلمات البحث',
  registerNo: 'رقم التسجيل.',
  tradeName: 'الاسم التجاري.',
  requests: 'الطلبات',
  actions: 'أوامر',
};
const customers = {
  customers: 'العملاء',
  totalCustomers: 'جميع العملاء',
  noCustomersFound: 'لم يتم إيجاد عملاء.',
  loadingCustomers: 'جاري تحميل بيانات العملاء. برجاء الإنتظار',

};

const primevueConfig = {
  startsWith: 'يبدأ بـ',
  contains: 'يحتوي على',
  notContains: 'لا يحتوي على',
  endsWith: 'ينتهي بـ',
  equals: 'يساوي',
  notEquals: 'لا يساوي',
  noFilter: 'بدون فلتر',
  lt: 'أقل من',
  lte: 'أقل من أو يساوي',
  gt: 'أكبر من',
  gte: 'أكبر من أو يساوي',
  dateIs: 'التاريخ هو',
  dateIsNot: 'التاريخ ليس',
  dateBefore: 'التاريخ قبل',
  dateAfter: 'التاريخ بعد',
  clear: 'مسح',
  apply: 'تطبيق',
  matchAll: 'موافق لجميع',
  matchAny: 'موافق لأي',
  addRule: 'إضافة قاعدة',
  removeRule: 'إزالة قاعدة',
  accept: 'نعم',
  reject: 'لا',
  choose: 'اختر',
  upload: 'تحميل',
  cancel: 'إلغاء',
  completed: 'مكتمل',
  pending: 'قيد الانتظار',
  fileSizeTypes: 'ب_ك.ب_م.ب_ج.ب_ت.ب_ب.ب_إ.ب_ز.ب_ي.ب',
  dayNames: 'الأحد_الإثنين_الثلاثاء_الأربعاء_الخميس_الجمعة_السبت',
  dayNamesShort: 'الأحد_الإثنين_الثلاثاء_الأربعاء_الخميس_الجمعة_السبت',
  dayNamesMin: 'ح_ن_ث_ر_خ_ج_س',
  monthNames: 'يناير_فبراير_مارس_أبريل_مايو_يونيو_يوليو_أغسطس_سبتمبر_أكتوبر_نوفمبر_ديسمبر',
  monthNamesShort: 'يناير_فبراير_مارس_أبريل_مايو_يونيو_يوليو_أغسطس_سبتمبر_أكتوبر_نوفمبر_ديسمبر',
  chooseYear: 'اختر السنة',
  chooseMonth: 'اختر الشهر',
  chooseDate: 'اختر التاريخ',
  prevDecade: 'العقد السابق',
  nextDecade: 'العقد التالي',
  prevYear: 'السنة السابقة',
  nextYear: 'السنة التالية',
  prevMonth: 'الشهر السابق',
  nextMonth: 'الشهر التالي',
  prevHour: 'الساعة السابقة',
  nextHour: 'الساعة التالية',
  prevMinute: 'الدقيقة السابقة',
  nextMinute: 'الدقيقة التالية',
  prevSecond: 'الثانية السابقة',
  nextSecond: 'الثانية التالية',
  am: 'ص',
  pm: 'م',
  today: 'اليوم',
  weekHeader: 'أسبوع',
  firstDayOfWeek: '0',
  showMonthAfterYear: 'false',
  dateFormat: 'dd/mm/yy',
  weak: 'ضعيف',
  medium: 'متوسط',
  strong: 'قوي',
  passwordPrompt: 'أدخل كلمة المرور',
  searchMessage: 'يوجد {0} نتيجة',
  selectionMessage: 'تم اختيار {0} عنصر',
  emptySelectionMessage: 'لا يوجد عنصر مختار',
  emptySearchMessage: 'لم يتم العثور على نتائج',
  fileChosenMessage: '{0} ملف',
  noFileChosenMessage: 'لم يتم اختيار ملف',
  emptyMessage: 'لا توجد خيارات متاحة',
  aria: {
    trueLabel: 'صحيح',
    falseLabel: 'غير صحيح',
    nullLabel: 'غير مختار',
    star: '1 نجمة',
    stars: '{star} نجوم',
    selectAll: 'جميع العناصر مختارة',
    unselectAll: 'جميع العناصر غير مختارة',
    close: 'إغلاق',
    previous: 'السابق',
    next: 'التالي',
    navigation: 'تنقل',
    scrollTop: 'انتقل إلى الأعلى',
    moveTop: 'انتقل إلى الأعلى',
    moveUp: 'انتقل لأعلى',
    moveDown: 'انتقل لأسفل',
    moveBottom: 'انتقل إلى الأسفل',
    moveToTarget: 'انتقل إلى الهدف',
    moveToSource: 'انتقل إلى المصدر',
    moveAllToTarget: 'نقل الكل إلى الهدف',
    moveAllToSource: 'نقل الكل إلى المصدر',
    pageLabel: 'الصفحة {page}',
    firstPageLabel: 'الصفحة الأولى',
    lastPageLabel: 'الصفحة الأخيرة',
    nextPageLabel: 'الصفحة التالية',
    prevPageLabel: 'الصفحة السابقة',
    rowsPerPageLabel: 'الصفوف في الصفحة',
    jumpToPageDropdownLabel: 'الانتقال إلى صفحة منسدلة',
    jumpToPageInputLabel: 'الانتقال إلى صفحة إدخال',
    selectRow: 'الصف مختار',
    unselectRow: 'الصف غير مختار',
    expandRow: 'توسيع الصف',
    collapseRow: 'طي الصف',
    showFilterMenu: 'عرض قائمة الفلتر',
    hideFilterMenu: 'إخفاء قائمة الفلتر',
    filterOperator: 'عامل الفلتر',
    filterConstraint: 'قيد الفلتر',
    editRow: 'تحرير الصف',
    saveEdit: 'حفظ التعديلات',
    cancelEdit: 'إلغاء التعديلات',
    listView: 'عرض القائمة',
    gridView: 'عرض الشبكة',
    slide: 'شريحة',
    slideNumber: '{slideNumber}',
    zoomImage: 'تكبير الصورة',
    zoomIn: 'تكبير',
    zoomOut: 'تصغير',
    rotateRight: 'تدوير لليمين',
    rotateLeft: 'تدوير لليسار',
  },
};

const mainReport = {
  title: 'التقرير الرئيسي',
  selectColumns: 'اختر الأعمدة',
  clear: 'إزالة التصفيات',
  export: 'تحميل',
  noData: 'لم يتم العثور على عملاء.',
  customerNumber: 'رقم العميل',
  companyName: 'اسم الشركة',
  approvalType: 'نوع الموافقة',
  approvalAmount: 'مقدار الموافقة',
  approvalDate: 'تاريخ الموافقة',
  expiryDate: 'تاريخ الانتهاء',
  daysInArrears: 'عدد الأيام المتأخرة',
  searchById: 'البحث بالرقم',
  searchByCompanyName: 'البحث باسم الشركة',
  searchByApprovalType: 'البحث بنوع الموافقة',
  datePlaceholder: 'يوم/شهر/سنة',
  any: 'أي',
  typeOfApproval: 'نوع الموافقة',
  status: 'الحالة',
  total: 'إجمالى قيمة الموافقات:',
};
const detailedReport = {
  title: 'تقرير مفصل',
  selectColumns: 'اختر الأعمدة',
  clear: 'إزالة التصفيات',
  export: 'تحميل',
  noData: 'لا توجد عملاء.',
  customerNumber: 'رقم العميل',
  companyName: 'اسم الشركة',
  governorate: 'المحافظة',
  sector: 'القطاع',
  industry: 'الصناعة',
  subIndustry: 'الفرع الصناعي',
  numberOfApprovals: 'عدد الموافقات',
  totalAmount: 'المبلغ الإجمالي',
  outstanding: 'المستحق',
  daysInArrears: 'عدد الأيام المتأخرة',
  percentOfPortfolio: 'نسبة من المحفظة',
  status: 'الحالة',
  searchById: 'البحث بالرقم',
  searchByCompanyName: 'البحث باسم الشركة',
  searchByIndustry: 'البحث بالصناعة',
  searchBySector: 'البحث بالقطاع',
  searchBySubIndustry: 'البحث بالفرع الصناعي',
  searchByApprovals: 'البحث بالموافقات',
  searchByAmount: 'البحث بالمبلغ',
  searchByDaysInArrears: 'البحث بعدد الأيام المتأخرة',
  searchByOutstanding: 'البحث بالمستحق',
  searchByPercent: 'البحث بنسبة من المحفظة',
  datePlaceholder: 'يوم/شهر/سنة',
  any: 'أي',
  totalNumberOfApprovals: 'عدد الموافقات الإجمالي:',
  totalAmounts: 'إجمالى قيمة الموافقات:',
  totalOutstanding: 'إجمالى المستحق:',
  totalPercentOfPortfolio: 'إجمالى النسبة من المحفظة:',

};
const industryConcentrationReport = {
  title: 'تقرير تركيز الصناعة',
  selectColumns: 'اختر الأعمدة',
  clear: 'إزالة التصفيات',
  export: 'تحميل',
  noData: 'لم يتم العثور على بيانات.',
  numberOfCustomers: 'عدد العملاء',
  industry: 'الصناعة',
  totalAmount: 'المبلغ الموافق عليه',
  sectorCapLimit: 'الحد الأقصى للقطاع',
  outstanding: 'المبلغ المستحق',
  percentOfPortfolio: 'النسبة من المحفظة',
  percentageOfSectorCapLimit: 'نسبة الحد الأقصى للقطاع',
  searchById: 'البحث بعدد العملاء',
  searchByCompanyName: 'البحث باسم الشركة',
  searchByIndustry: 'البحث بالصناعة',
  searchBySector: 'البحث بحد أقصى للقطاع',
  searchBySectorCapPercent: 'البحث بنسبة حد القطاع',
  searchBySubIndustry: 'البحث بالصناعة الفرعية',
  searchByAmount: 'البحث بالمبلغ',
  searchByOutstanding: 'البحث بالمبلغ المستحق',
  searchByPercent: 'البحث بنسبة من المحفظة',
  datePlaceholder: 'مم/يوم/سنة',
  any: 'أي',
  totalNumberOfCustomers: 'أجمالى عدد العملاء:',
  totalAmounts: 'إجمالى قيمة الموافقات:',
  totalOutstanding: 'إجمالى المستحق:',

};
const orrForm = {
  mainTitle: 'تقرير ORR',
  performanceIndicators: 'مؤشرات الأداء',
  strengthOfFinancialPosition: 'قوة المركز المالي',
  revenueGrowth: 'نمو الإيرادات',
  grossProfitMargin: 'هامش الربح الإجمالي',
  netProfitMargin: 'هامش الربح الصافي',
  netProfitBeforeTax: 'الربح الصافي قبل الفوائد والضرائب / نفقات التمويل',
  currentRatio: 'نسبة السيولة الحالية',
  leverage: 'الرافعة المالية',
  riskDegree: 'درجة المخاطرة',
  riskRating: 'تصنيف المخاطر',
  totalScore: 'إجمالي النقاط',
  confirmHeader: 'تأكيد',
  confirmationMessage: 'هل أنت متأكد أنك تريد الحفظ؟',
  dropdownPlaceholder: 'يرجى الاختيار',
  submit: 'إرسال',
  cancel: 'إلغاء',
  yes: 'نعم',
  no: 'لا',
  progressSpinner: 'شريط تقدم مخصص',
  select: 'اختر',
  selectYear: 'اختر السنة',
  // Sections
  leasedAssetQuality: 'جودة الأصول المؤجرة',
  industry: 'الصناعة',
  institution: 'المؤسسة',
  management: 'الإدارة',
  competition: 'المنافسة',
  facilitiesOutstandingBalances: 'التسهيلات / الأرصدة المستحقة',
  cashFlow: 'التدفق النقدي',
  // Parameters
  assetType: 'نوع الأصل',
  downPaymentSecurityMargin: 'الدفع الأولي (الهامش الأمني)',
  assetConditionUsedNew: 'حالة الأصل (مستعمل / جديد)',
  isTheAssetStandardOrCustomized: 'هل الأصل قياسي أم مخصص؟',
  industryStability: 'استقرار الصناعة',
  profitability: 'الربحية',
  seasonality: 'الموسمية',
  governmentRegulations: 'التنظيمات الحكومية',
  businessDURATION: 'مدة الأعمال',
  legalFORM: 'الهيئة القانونية',
  managementStructure: 'هيكل الإدارة',
  seniorManagementExperience: 'تجربة الإدارة العليا',
  thePracticeOfDelegationAuthoritySecondLineManagement: 'ممارسة تفويض السلطات / الإدارة الثانية',
  companyPlan: 'خطة الشركة',
  productDiversification: 'تنويع المنتجات',
  customerBase: 'قاعدة العملاء',
  supplierBase: 'قاعدة الموردين',
  clientSCustomersProfileWhoAreThey: 'سمعة عملاء الشركة (من هم)',
  facilitiesWithBanksOrOthers: 'التسهيلات مع البنوك أو الآخرين',
  relationWithClientsSuppliers: 'العلاقة مع العملاء والموردين',
  shareholdersSupportInCaseOfDifficulties: 'دعم المساهمين في حالة الصعوبات',
  netCashFlowsFromOperatingActivitiesNOPAT: 'صافي التدفقات النقدية من الأنشطة التشغيلية (NOPAT)',
  netCashFlowsFromOperatingActivitiesCOPAT: 'صافي التدفقات النقدية من الأنشطة التشغيلية (COPAT)',
  netCashFlowsInterestAndPremiumsDueDuringTheYear: 'صافي التدفقات النقدية / الفوائد والأقساط المستحقة خلال السنة',
  // Options
  commercialOrAdminBuilding: 'مبنى تجاري أو إداري',
  industrialLandOrBuilding: 'صناعي (أرض أو مبنى)',
  commercialVehicles: 'مركبات تجارية',
  passengerVehicles: 'مركبات ركاب',
  trucks: 'شاحنات',
  heavyEquipment: 'معدات ثقيلة',
  machinesAndProductionLines: 'آلات وخطوط إنتاج',
  equipments: 'معدات',
  downPaymentMoreThanAKELCPolicy: 'دفعة مقدمة أكثر من سياسة AKELC',
  lessThanAKELCPolicy: 'أقل من سياسة AKELC',
  new: 'جديد',
  used: 'مستعمل',
  standard: 'قياسي',
  partiallyCustomized: 'مخصص جزئيًا',
  highlyCustomized: 'مخصص بدرجة عالية',
  highStability: 'استقرار عالي',
  stableAndGrowing: 'مستقر وينمو',
  exposedToFluctuationsDueToSurroundingFactors: 'مكشوف للتقلبات بسبب العوامل المحيطة',
  theIndustryIsExposedToAdverseConditionsAndThereAreRisksOfDeterioration: 'الصناعة مكشوفة للظروف السيئة وهناك مخاطر للتدهور',
  continuingDeterioration: 'تدهور مستمر',
  consistentlyProfitableDuringRecessionExpansion: 'مربحة باستمرار خلال الركود والتوسع',
  profitabilityDuringExpansionButMildlyUnprofitableDuringRecession: 'مربحة خلال التوسع ولكن غير مربحة قليلاً خلال الركود',
  unprofitableDuringRecessionAndExpansion: 'غير مربحة خلال الركود والتوسع',
  lowSeasonality: 'موسمية منخفضة',
  moderateSeasonality: 'موسمية معتدلة',
  highSeasonality: 'موسمية عالية',
  regulatorProtectsOrEnhancesIndustry: 'الجهة المنظمة تحمي أو تعزز الصناعة',
  noGovernmentRegulations: 'لا توجد تنظيمات حكومية',
  regulationsHaveSignificantAdverseImpactOnIndustry: 'التنظيمات لها تأثير سلبي كبير على الصناعة',
  newCompanyStartup: 'شركة جديدة (شركة ناشئة)',
  newCompanyAndExtensionForCurrentBusiness: 'شركة جديدة وتوسيع للأعمال الحالية',
  durationOfBusinessFrom3To5Years: 'مدة العمل من 3 إلى 5 سنوات',
  moreThe5Years: 'أكثر من 5 سنوات',
  soleProprietorship: 'ملكية فردية',
  limitedPartnership: 'شركة تضامن محدودة',
  generalPartnership: 'شركة تضامن عامة',
  jointStock: 'شركة مساهمة',
  individualManagement: 'إدارة فردية',
  familyManagement: 'إدارة عائلية',
  theMultiplicityOfPartnersInvolvedInTheManagementOfTheActivity: 'تعدد الشركاء في إدارة النشاط',
  professionalStructuredManagement: 'إدارة منظمة محترفة',
  recentExperienceLessThan2Years: 'تجربة حديثة (أقل من 2 سنة)',
  acceptableExperienceMoreThan2YearsTo5Years: 'تجربة مقبولة (أكثر من سنتين إلى 5 سنوات)',
  goodExperienceMoreThan5YearsTo10Years: 'تجربة جيدة (أكثر من 5 سنوات إلى 10 سنوات)',
  highExperienceMoreThan10Years: 'تجربة عالية (أكثر من 10 سنوات)',
  delegationOfAuthority: 'تفويض السلطة',
  secondLinePresentButDecisionMakingIsCentralized: 'الخط الثاني موجود ولكن اتخاذ القرارات مركزي',
  centralizedDecisionMakingAbsenceOfSecondLine: 'اتخاذ القرارات مركزي وغياب الخط الثاني',
  theCompanyHasAPlansFor3Years: 'الشركة لديها خطط لمدة 3 سنوات',
  theCompanyHasAPlanForOneYear: 'الشركة لديها خطة لمدة عام واحد',
  theCompanyHasNoClearPlanning: 'الشركة ليس لديها تخطيط واضح',
  high: 'تنوع عالي',
  moderate: 'تنوع معتدل',
  weakOrLackOfCapacityToDiversifyProductsAndServices: 'ضعيف أو يفتقر إلى القدرة على تنويع المنتجات والخدمات',
  theCompanyHasABroadAndDiversifiedClientBase: 'قاعدة عملاء واسعة ومتنوعة',
  theCompanyTurnoverIsConcentratedOnFewMajorClients: 'الشركة تعتمد على عدد قليل من العملاء الرئيسيين',
  theCompanyHasOneOrTwoClientsOnly: 'العميل واحد أو اثنان فقط',
  theCompanyHasABroadAndDiversifiedBaseOfSuppliers: 'قاعدة موردين واسعة ومتنوعة',
  theCompanyHasExclusiveTwoOrThreeSuppliers: 'تعتمد على موردين اثنين أو ثلاثة فقط',
  theCompanyIsStronglyDependentOnAUniqueSupplier: 'تعتمد بشكل كبير على مورد واحد',
  wellKnown: 'معروف',
  notWellKnown: 'غير معروف',
  badReputation: 'سمعة سيئة',
  regular: 'منتظم',
  noBalance: 'بدون رصيد',
  lateDuesMoreThanThreeMonths: 'تأخير في الدفعات أكثر من ثلاثة أشهر',
  regularWithArrears: 'منتظم مع وجود مستحقات',
  irregularTransactions: 'معاملات غير منتظمة',
  regularTransactions: 'معاملات منتظمة',
  noAvailableInformation: 'لا توجد معلومات متاحة',
  theCompanyEnjoyTheSupportOfItsMotherCoSisterCoShareholdersEtc: 'الشركة تستفيد من دعم الشركة الأم أو الشركات الشقيقة أو المساهمين',
  theCompanyDoesNotEnjoyAnySupport: 'الشركة لا تستفيد من أي دعم',
  sufficientToCoverWorkingCapitalAndLongTermInvestment: 'كافٍ لتغطية رأس المال العامل والاستثمار طويل الأجل',
  sufficientToCoverEitherWorkingCapitalOrLongTermInvestmentOnly: 'كافٍ لتغطية رأس المال العامل أو الاستثمار طويل الأجل فقط',
  negativeCashFlowsInThePreviousTwoPhases: 'تدفقات نقدية سلبية في المرحلتين السابقتين',
  sufficientAtHighAndStableRatesAtLeast2Times: 'كافٍ بمعدلات مرتفعة وثابتة (على الأقل مرتين)',
  sufficientAtGoodAndStableRatesGreaterThan1Time: 'كافٍ بمعدلات جيدة وثابتة (أكثر من مرة واحدة)',
  sufficientAtAcceptableAndStableRatesOnceStable: 'كافٍ بمعدلات مقبولة وثابتة (مرة واحدة ثابتة)',
  insufficientBelow1: 'غير كافٍ (أقل من 1)',
};
const dashboardPage = {
  totalCustomers: 'إجمالي العملاء',
  newCustomersInNovember: 'في نوفمبر',
  targetAchieved: 'الهدف محقق',
  totalRequests: 'إجمالي الطلبات',
  submittedRequests: 'مقدمة',
  draftRequests: 'مسودة',
  totalInvestigations: 'إجمالي التحقيقات',
  approvedInvestigations: 'تمت الموافقة',
  rejectedInvestigations: 'مرفوض',
  draftCustomers: 'العملاء المسودة',
  customerName: 'اسم العميل',
  submittedDate: 'تاريخ التقديم',
  achievementProgress: 'تقدم الإنجاز',
  businessAchievedGoals: 'الأهداف المحققة للعمل',
  businessTotalTarget: 'إجمالي هدف العمل 1200',
  investigationAchievedGoals: 'الأهداف المحققة للتحقيق',
  investigationTotalTarget: 'إجمالي هدف التحقيق 800',
  finishedRequests: 'الطلبات المنتهية',
  totalRequests963: 'إجمالي الطلبات 963',
  businessDepartmentAchievement: 'إنجاز قسم الأعمال',
  draftInvestigations: 'تحقيق مسودة',
  investigationId: 'معرف التحقيق',
  completedPercentage: 'نسبة الإنجاز',
  notification: 'الإشعارات',
  today: 'اليوم',
  addedNewCustomers: 'أضاف 3 عملاء جدد',
  rejectedRequests: 'تم رفضه',
  yesterday: 'الأمس',
  submittedInvestigations: 'تم تقديم 5 تحقيقات',
  approvedRequests: 'تمت الموافقة على 4 طلبات.',
  totalInvestigation: 'إجمالي التحقيقات',
  inNovember: ' في نوفمبر',
  submitted: 'تم تقديمه',
  draft: 'مسودة',
  approved: 'مقبول',
  rejected: 'مرفوض',
  draftInvestigation: 'مسوده التحقيق',
  new: 'جديد',
};

export default {
  customerFormPage,
  requestFormPage,
  investigationFormPage,
  facilitiesPage,
  guaranteePage,
  creditPage,
  months,
  messages,
  customers,
  primevueConfig,
  mainReport,
  detailedReport,
  industryConcentrationReport,
  creditMemoPage,
  headerCards,
  riskPage,
  riskMemoForm,
  orrForm,
  projectionPage,
  dashboardPage,
  requestForm,
  cashflowPage,
};
