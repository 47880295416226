import { createStore } from 'vuex';
import users from './modules/users';
import options from './modules/options';
import customer from './modules/customer';
import request from './modules/request';
import investigation from './modules/investigation';
import credit from './modules/credit';
import risk from './modules/risk';
import projection from './modules/projection';
import scoring from './modules/scoring';
import charts from './modules/charts';
import reports from './modules/reports';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    users,
    customer,
    request,
    options,
    investigation,
    credit,
    risk,
    projection,
    scoring,
    charts,
    reports,
  },
});
